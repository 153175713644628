import { handleActions } from 'redux-actions';

import * as c from '@/redux/constants/events';

const initialState = {
    events: [],
    event: {
        id: 0,
        title: '',
        slug: '',
        description: '',
        starting_date: '',
        ending_date: '',
        year: 0,
        main_image: '',
        status: '',
        photos_count: 0,
        portraits_count: 0,
        classification: false,
        public: false,
        allow_downloads: false,
        max_event_photos: 50000,
        max_event_portraits: 800,
        photo_groups_zip_url: ""
    },
    errors: {},
    state: {
        isEventsLoading: true,
        isEventLoading: true,
        isCreateEventLoading: false,
        isDeleteEventLoading: false,
        isEditEventLoading: false,
        isGroupedPhotosZipLoading: false,
        isZipping: false,
        isPolling: false,
    },

};

const eventsData = handleActions(
    new Map([
        [
            c.GET_EVENTS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventsLoading: true
                }
            })
        ],
         [
            c.GET_EVENTS_SUCCESS,
            (state, { payload }) => ({
                ...state,
                events: payload,
                state: {
                    ...state.state,
                    isEventsLoading: false
                },
            })
        ],
        [
            c.GET_EVENTS_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventsLoading: false
                }
            })
        ],
        [
            c.CREATE_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCreateEventLoading: true
                }
            })
        ],
        [
            c.CREATE_EVENT_SUCCESS,
            (state, { payload }) => ({
                ...state,
                event: payload,
                state: {
                    ...state.state,
                    isCreateEventLoading: false
                },
            })
        ],
        [
            c.CREATE_EVENT_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isCreateEventLoading: false
                }
            })
        ],
        [
            c.EDIT_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditEventLoading: true
                }
            })
        ],
        [
            c.EDIT_EVENT_SUCCESS,
            (state, { payload }) => ({
                ...state,
                event: payload,
                state: {
                    ...state.state,
                    isEditEventLoading: false
                },
            })
        ],
        [
            c.EDIT_EVENT_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEditEventLoading: false
                }
            })
        ],
        [
            c.GET_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventLoading: true
                }
            })
        ],
        [
            c.GET_EVENT_SUCCESS,
            (state, { payload }) => ({
                ...state,
                event: payload,
                state: {
                    ...state.state,
                    isEventLoading: false
                },
            })
        ],
        [
            c.GET_EVENT_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isEventLoading: false
                }
            })
        ],
        [
            c.DELETE_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isDeleteEventLoading: true
                }
            })
        ],
        [
            c.DELETE_EVENT_SUCCESS,
            (state ) => ({
                ...state,
                event: initialState.event,
                state: {
                    ...state.state,
                    isDeleteEventLoading: false
                },
            })
        ],
        [
            c.DELETE_EVENT_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isDeleteEventLoading: false
                }
            })
        ],
        [
            c.START_POLLING_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isPolling: true
                }
            })
        ],
        [
            c.STOP_POLLING_EVENT,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isPolling: false
                }
            })
        ],
        [
            c.GET_GROUPED_PHOTOS_ZIP,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isGroupedPhotosZipLoading: true
                }
            })
        ],
        [
            c.GET_GROUPED_PHOTOS_ZIP_SUCCESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isGroupedPhotosZipLoading: false
                },
            })
        ],
        [
            c.GET_GROUPED_PHOTOS_ZIP_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isGroupedPhotosZipLoading: false
                }
            })
        ],
        [
            c.CREATE_DOWNLOADABLE_ZIP,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isZipping: true
                }
            })
        ],
        [
            c.CREATE_DOWNLOADABLE_ZIP_SUCCESS,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isZipping: false
                }
            })
        ],
        [
            c.CREATE_DOWNLOADABLE_ZIP_FAILURE,
            (state) => ({
                ...state,
                state: {
                    ...state.state,
                    isZipping: false
                }
            })
        ],
    ]),
    initialState
);

export default eventsData;