import React  from 'react';
import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '@/components/ui/navigation-menu';
import { buttonVariants } from '@/components/ui/button';
import logo from '@/assets/icons/logo-horizontal.png';


const Navbar = () => {
    return (
        <header className="sticky border-b-[1px] top-0 z-40 w-full bg-white/75 backdrop-blur-md dark:border-b-slate-700 dark:bg-background">
            <NavigationMenu className="mx-auto">
                <NavigationMenuList className="container h-20 px-4 w-screen flex justify-between ">
                    <NavigationMenuItem className="font-weight-500 flex">
                        <a
                            rel="noreferrer noopener"
                            href="/"
                            className="mx-1 font-bold text-xl flex"
                        >
                            <img src={logo} alt="Logo" width="180" />
                        </a>
                    </NavigationMenuItem>

                    {/* desktop */}
                    <nav className="flex gap-2">
                        <a rel="noreferrer noopener" href="/"
                           className={`text-[1.12rem] ${buttonVariants({variant: "ghost"})}`}>
                            Return to Home
                        </a>
                    </nav>

                    <div className="hidden md:flex gap-2">
                        <a
                            href="/login"
                            className={`border ${buttonVariants({ variant: "outline" })}`}
                        >
                            Login
                        </a>
                        <a
                            href='https://calendar.app.google/FHtScM3SXBk5EU2P7'
                            rel='noopener noreferrer'
                            target="_blank"
                            className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-white font-weight-300
                                py-2 px-4 rounded-lg shadow-md hover:from-indigo-500 hover:to-indigo-900
                                transition-transform duration-200"
                        >
                            📅 Book a demo
                        </a>
                    </div>
                </NavigationMenuList>
            </NavigationMenu>
        </header>
    );
};
export default Navbar;