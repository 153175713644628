import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUser } from '@/redux/actions';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Separator } from '@/components/ui/separator';
import { isValidEmail, isValidPassword } from "@/utils/tools";


const AccountSettings = ({ user }) => {
    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const { isEditUserLoading } = useSelector(state => state.user?.state);

    const [newAvatar, setNewAvatar] = useState(null);
    const [firstName, setFirstName] = useState(user?.first_name);
    const [lastName, setLastName] = useState(user?.last_name);
    const [email, setEmail] = useState(user?.email);
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [profileErrors, setProfileErrors] = useState({});
    const [passwordErrors, setPasswordErrors] = useState({});
    const detailsChanged = firstName !== user.first_name || lastName !== user.last_name || email !== user.email || newAvatar;
    const passwordsFilled = currentPassword && newPassword && confirmNewPassword;

    const handleFileSelect = () => {
        if (isEditUserLoading) return;

        fileInputRef.current.click();
    };

    const handleProfileUpdate = () => {
        const newErrors = {};
        if (!firstName || firstName.length < 2) newErrors.firstName = 'Please fill in your first name';
        if (!lastName || lastName.length < 2) newErrors.lastName = 'Please fill in your last name';
        if (!email || !isValidEmail(email)) newErrors.email = 'Please fill in your email address';

        setProfileErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        if (firstName && firstName !== user?.first_name) {
            formData.append('first_name', firstName);
        }
        if (lastName && lastName !== user?.last_name) {
            formData.append('last_name', lastName);
        }
        if (email && email !== user?.email) {
            formData.append('email', email);
        }
        if (newAvatar) {
            formData.append('avatar', newAvatar);
        }

        Array.from(formData.keys()).length > 0 && dispatch(editUser({ userId: user.id, formData }));
        setNewAvatar(null);
    };

    const handlePasswordUpdate = () => {
        const newErrors = {};
        if (!currentPassword) newErrors.currentPassword = 'Please fill in your current password';
        if (!newPassword) newErrors.newPassword = 'Please fill in a new password';
        if (!confirmNewPassword) newErrors.confirmNewPassword = 'Please fill in your new password again';
        if (newPassword && !isValidPassword(newPassword)) {
            newErrors.newPassword = 'Password must be at least 8 characters long and contain a letter and a number.'
        } else if (currentPassword && newPassword && currentPassword === newPassword) {
            newErrors.newPassword = 'New password must be different from your current password.'
        }
        if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) newErrors.confirmNewPassword = 'Passwords do not match';
        setPasswordErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        formData.append('current_password', currentPassword);
        formData.append('new_password', newPassword);

        dispatch(editUser({ userId: user.id, formData }));
        resetPasswordField();
    };

    const resetPasswordField = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }

    const handleCancel = () => {
        setShowPasswordFields(false);
        resetPasswordField();
    }

    return (
        <div className="grid gap-1 py-1">
            <h3 className="text-lg font-weight-400 col-span-12">Profile</h3>
            <p className="col-span-12 text-muted-foreground font-weight-400 mt-1">Your avatar</p>
            <div className="flex items-center col-span-12">
                <Avatar className="w-14 h-14 border border-gray-100/70">
                    {user?.profile?.avatar || newAvatar ?
                        <AvatarImage
                            src={newAvatar ? URL.createObjectURL(newAvatar) : user?.profile?.avatar}
                            className="w-full h-full object-cover"
                        />
                        :
                        <AvatarFallback>{firstName[0]}</AvatarFallback>
                    }
                </Avatar>
                <p
                    className="ml-4 text-sm text-primary font-weight-500 text-right cursor-pointer hover:underline"
                    onClick={handleFileSelect}
                >
                    Upload a new avatar
                </p>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => setNewAvatar(e.target.files[0])}
                    className="hidden"
                    accept="image/*"
                />
            </div>

            <div className="grid grid-cols-2 gap-5 col-span-12 mt-4">
                <Label htmlFor="firstName" className="text-muted-foreground font-weight-400">
                    Your first name<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
                <Label htmlFor="lastName" className="text-muted-foreground font-weight-400">
                    Your last name<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
            </div>
            <div className="grid grid-cols-2 gap-5 col-span-12">
                <div>
                    <Input id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    {profileErrors.firstName &&
                        <p className="text-red-500 text-sm mt-1 w-max">{profileErrors.firstName}</p>}
                </div>
                <div>
                    <Input id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    {profileErrors.lastName &&
                        <p className="text-red-500 text-sm mt-1 w-max">{profileErrors.lastName}</p>}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5 col-span-12 mt-4">
                <Label htmlFor="email" className="text-muted-foreground font-weight-400">
                    Your email address<span className="text-red-800 text-sm ml-0.5">*</span>
                </Label>
            </div>
            <div className="grid grid-cols-2 gap-5 col-span-12">
                <div>
                    <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    {profileErrors.email && <p className="text-red-500 text-sm mt-1 w-max">{profileErrors.email}</p>}
                </div>
            </div>
            <div className="flex justify-end col-span-12 mt-4">
                {isEditUserLoading && <Spinner className="mr-3" size="sm"/>}
                <Button
                    variant={isEditUserLoading || !detailsChanged ? 'disabled' : ''}
                    onClick={!isEditUserLoading ? handleProfileUpdate : null}
                    className="col-span-2"
                >
                    Update profile
                </Button>
            </div>
            <Separator className="my-4 bg-black bg-opacity-10 col-span-12"/>
            <h3 className="text-lg font-weight-400 col-span-12">Password</h3>
            {!showPasswordFields ?
                <div className="grid grid-cols-12 gap-5 col-span-12">
                    <p className="col-span-8 text-sm">Change the password for your account</p>
                    <p
                        className="col-span-4 text-sm text-primary font-weight-500 text-right cursor-pointer hover:underline"
                        onClick={() => setShowPasswordFields(!showPasswordFields)}
                    >
                        Change password
                    </p>
                </div>
                :
                <div className="col-span-12 grid gap-1 py-1">
                    <Label htmlFor="currentPassword" className="text-muted-foreground font-weight-400 col-span-12 mt-2">Current
                        Password<span className="text-red-800 text-sm ml-0.5">*</span></Label>
                    <Input id="currentPassword" type="password" className="col-span-12" value={currentPassword}
                           onChange={(e) => setCurrentPassword(e.target.value)}/>
                    {passwordErrors.currentPassword &&
                        <p className="text-red-500 text-sm mt-1 w-max">{passwordErrors.currentPassword}</p>}

                    <Label htmlFor="newPassword" className="text-muted-foreground font-weight-400 col-span-12 mt-2">New
                        Password<span className="text-red-800 text-sm ml-0.5">*</span></Label>
                    <Input id="newPassword" type="password" className="col-span-12" value={newPassword}
                           onChange={(e) => setNewPassword(e.target.value)}/>
                    {passwordErrors.newPassword &&
                        <p className="text-red-500 text-sm mt-1 w-max">{passwordErrors.newPassword}</p>}

                    <Label htmlFor="confirmNewPassword"
                           className="text-muted-foreground font-weight-400 col-span-12 mt-2">Confirm New Password<span
                        className="text-red-800 text-sm ml-0.5">*</span></Label>
                    <Input id="confirmNewPassword" type="password" className="col-span-12" value={confirmNewPassword}
                           onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                    {passwordErrors.confirmNewPassword &&
                        <p className="text-red-500 text-sm mt-1 w-max">{passwordErrors.confirmNewPassword}</p>}

                    <div className="flex justify-end col-span-12 space-x-2 mt-4">
                        {isEditUserLoading && <Spinner className="mr-3" size="sm"/>}
                        <Button variant="outline" onClick={handleCancel}>Cancel</Button>
                        <Button
                            variant={isEditUserLoading || !passwordsFilled ? 'disabled' : ''}
                            onClick={passwordsFilled ? handlePasswordUpdate : null}
                            className="col-span-2"
                        >
                            Update Password
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};

export default AccountSettings;
