import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.svg';
import { ReactComponent as CaretDownIcon } from '@/assets/icons/caret_down.svg';
import { LogOut } from 'lucide-react';
import { getUserData, logoutUser, getBusiness } from '@/redux/actions';
import UploadProgress from '@/components/Photos/UploadProgress';
import SettingsModal from '@/components/Settings/SettingsModal';
import CartModal from '@/components/Cart/CartModal';
import logo from '@/assets/icons/logo-horizontal.png';


export default function GalleryHeader() {
    const dispatch = useDispatch();
    const location = useLocation();

    const user = useSelector(state => state.user);
    const business = useSelector(state => state.businesses?.business);

    const { name, avatar } = user?.profile;
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        user?.token && dispatch(getUserData());
    }, [dispatch, user?.token]);

    useEffect(() => {
        user?.business?.id && dispatch(getBusiness({businessId: user?.business?.id}));
    }, [dispatch, user?.business?.id]);


    const isActive = (path) => {
        return location.pathname === path;
    };

    const handleLogOut = () => {
        dispatch(logoutUser());
        localStorage.removeItem('token');
        localStorage.removeItem('persist:root');
    };

    if (user?.token && business?.id) {
        return (
            <>
                <header className="fixed top-0 left-0 w-full z-50 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-md">
                    <div className="container flex h-20 max-w-screen-2xl items-center justify-between">
                        <div className="flex items-center">
                            <div className="mr-4 hidden md:flex">
                                <Link className="mr-6 flex items-center space-x-2" to="/events">
                                    <img src={logo} alt="Logo" width="200"/>
                                </Link>
                                <nav className="flex items-center gap-4 lg:gap-6 text-lg">
                                    <Link
                                        className={`transition-colors ${isActive('/events') ? 'text-accent-foreground' : 'hover:text-foreground/100 text-foreground/70'}`}
                                        to="/events">
                                        Events
                                    </Link>
                                </nav>
                            </div>
                            <button
                                className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-9 py-2 mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
                                type="button" aria-haspopup="dialog" aria-expanded={menuOpen}
                                onClick={() => setMenuOpen(!menuOpen)}>
                                <MenuIcon className="h-6 w-6 text-gray-800"/>
                                <span className="sr-only">Toggle Menu</span>
                            </button>
                        </div>
                        <div className="flex items-center relative">
                            <span className="text-gray-900">{business?.name}</span>
                            <nav className="flex items-center ml-4 relative">
                                <Avatar>
                                    {avatar ?
                                        <AvatarImage src={avatar} className="w-full h-full object-cover"/>
                                        :
                                        <AvatarFallback>{name[0]}</AvatarFallback>
                                    }
                                </Avatar>
                                <CartModal />
                                <div className="relative">
                                    <CaretDownIcon
                                        className={`bg-gray-200 p-2.5 h-10 w-10 text-gray-500 rounded-full cursor-pointer ${dropdownOpen && 'transform rotate-180'} `}
                                        onClick={() => setDropdownOpen(!dropdownOpen)}
                                    />
                                    {dropdownOpen && (
                                        <div
                                            className="absolute right-0 mt-2 w-48 py-2 bg-white border border-gray-200 rounded-lg shadow-md z-50">
                                            <SettingsModal user={user} business={business}/>
                                            <div
                                                className="flex align-middle items-center justify-start w-full hover:bg-gray-100">
                                                <LogOut className="ml-3 h-[20px] w-[20px]"/>
                                                <button onClick={handleLogOut}
                                                        className="block px-3 py-2 text-gray-800">Log out
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                    {menuOpen && (
                        <div className="md:hidden">
                            <nav className="flex flex-col items-start gap-4 p-4 text-lg bg-background">
                                <Link
                                    className={`transition-colors ${isActive('/events') ? 'text-accent-foreground' : 'hover:text-foreground/100 text-foreground/70'}`}
                                    to="/events">
                                    Events
                                </Link>
                            </nav>
                        </div>
                    )}
                </header>
                <UploadProgress/>
            </>
        );
    }

    return (
        <>
            <header className="fixed top-0 left-0 w-full z-50 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-md">
                <div className="container flex h-20 max-w-full items-center justify-between">
                    <div className="flex items-center">
                        <div className="mr-4 hidden md:flex">
                            <Link className="mr-6 flex items-center space-x-2" to="/">
                                <img src={logo} alt="Logo" width="200" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center relative">
                        <nav className="flex items-center ml-4 relative">
                            <CartModal />
                            <Link className={`ml-2 transition-colors ${isActive('/events') ? 'text-accent-foreground' : 'hover:text-foreground/100 text-foreground/70'}`} to="/login">
                                <Button>
                                    Business log in
                                </Button>
                            </Link>
                        </nav>
                    </div>
                </div>
            </header>
            <UploadProgress />
        </>
    );
}
