import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { shouldAllowCookies } from '@/utils/cookies';


const Tracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (shouldAllowCookies()) {
            if (!window.GA_INITIALIZED) {
                ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
                window.GA_INITIALIZED = true;
            }

            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search,
            });
        }
    }, [location]);

    return null;
};

export default Tracking;