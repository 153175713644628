import React, { useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { useDispatch, useSelector } from "react-redux";
import { CardContent, CardHeader } from '@/components/ui/card';
import { Spinner } from '@/components/ui/spinner';
import { getBusinessBillings } from "@/redux/actions";
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import withHeaderAndFooter from '@/components/HOCs/withHeaderAndFooter';
import UsageStats from '@/components/BillingAndUsage/UsageStats';
import Invoices from '@/components/BillingAndUsage/Invoices';


const BillingAndUsage = () => {
    const dispatch = useDispatch();
    const businessId = useSelector(state => state.user?.business?.id);
    const businessBillings = useSelector(state => state.businesses?.businessBillings);
    const verified = useSelector(state => state.user?.business?.verified);
    const { isBusinessBillingsLoading } = useSelector(state => state.businesses.state);

    useEffect(() => {
        businessId && dispatch(getBusinessBillings({ businessId }));
    }, [dispatch, businessId]);

    return (
        <div>
            <main className="grid flex-1 bg-gray-100 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <Card className="flex container max-w-screen-2xl flex-col mt-6">
                    <CardHeader className="flex justify-between text-left mb-10">
                        {!verified &&
                            <Alert className="border-yellow-500 bg-yellow-100/60 mb-4">
                                <AlertTitle className="text-yellow-700">Almost ready!</AlertTitle>
                                <AlertDescription className="text-yellow-700">
                                    Our team is reviewing your business, and you'll be able to set up your events very soon.
                                </AlertDescription>
                            </Alert>
                        }
                        <div className="flex justify-between text-left">
                            <div>
                                <p className="text-3xl font-weight-500 text-neutral-800">Billing & Usage</p>
                                <p className="text-md text-muted-foreground mt-1">Track your photo uploads, view monthly charges & invoices, and monitor usage.</p>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        {isBusinessBillingsLoading ?
                             <Spinner className="mt-4" size="large">
                                 <span className="py-3 text-gray-800">Loading your stats...</span>
                             </Spinner>
                            :
                            <>
                                <UsageStats businessBillings={businessBillings} verified={verified}/>
                                {businessBillings.length > 0 &&
                                    <Invoices businessBillings={businessBillings} />
                                }
                            </>
                        }
                    </CardContent>
                </Card>
            </main>
        </div>
    );
};

export default withHeaderAndFooter(BillingAndUsage);
