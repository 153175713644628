import React, { useEffect, useState } from 'react';
import { CardContent, } from '@/components/ui/card';
import { Minus, ImageUp, SquareUserRound, ScanFace, Edit, ImageDown, ImageOff, GlobeLock, Globe } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import moment from 'moment';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import DateRangePicker from "../ui/DateRangePicker";
import { Link } from "react-router-dom";


const EventDetails = ({ event, isEditMode, editedEvent, setEditedEvent, errors }) => {
    const {
        title,
        description,
        slug,
        starting_date,
        ending_date,
        main_image,
        status,
        photos_count,
        portraits_count,
        classification,
        public: isPublic,
        allow_downloads: allowDownloads
    } = event;

    const galleryUrl = `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_BASE_DOMAIN}/gallery/${slug}`;

    const [localDate, setLocalDate] = useState({
        from: new Date(editedEvent?.starting_date) || new Date(),
        to: new Date(editedEvent?.ending_date) || null
    });

    useEffect(() => {
        setLocalDate({
            from: editedEvent?.startingDate ? new Date(editedEvent?.startingDate) : null,
            to: editedEvent?.endingDate ? new Date(editedEvent?.endingDate) : editedEvent?.startingDate
        });
    }, [editedEvent]);

    const handleDateChange = (date) => {
        setLocalDate(date);
        if (date) {
            setEditedEvent((prevState) => ({
                ...prevState,
                startingDate: moment(date?.from).format("YYYY-MM-DD"),
                endingDate: date?.to ? moment(date?.to).format("YYYY-MM-DD") : moment(date?.from).format("YYYY-MM-DD")
            }));
        } else {
            setEditedEvent((prevState) => ({
                ...prevState,
                startingDate: null,
                endingDate: null
            }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setEditedEvent((prevState) => ({
                ...prevState,
                mainImage: URL.createObjectURL(file),
                mainImageFile: file
            }));
        }
    };

    return (
        <CardContent>
            {/* Header card */}
            <div className={`relative w-full h-72 bg-cover bg-center z-0 shadow-lg rounded-lg ${main_image ? 'bg-gray-100' :'bg-black/70'}`}
                 style={{backgroundImage: `url(${(isEditMode && editedEvent?.mainImage) || main_image})`}}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>

                {isEditMode && (
                    <label htmlFor="main_image_upload"
                           className="absolute top-4 right-4 bg-white p-2 rounded-full cursor-pointer flex items-center justify-center">
                        <Edit className="w-5 h-5 text-primary"/>
                        <input
                            id="main_image_upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                        />
                    </label>
                )}
                {/* Event details */}
                <div className="relative z-10 flex items-end justify-between w-3/4 h-full p-8">
                    <div className="flex flex-col items-left">
                        <h1 className="text-3xl font-weight-500 lg:text-4xl text-white">
                            {/* Event title */}
                            {!isEditMode ?
                                title
                                :
                                <>
                                    <Input
                                        id="title"
                                        value={editedEvent?.title}
                                        onChange={(e) => setEditedEvent(prevState => ({
                                            ...prevState,
                                            title: e.target.value
                                        }))}
                                        className="text-3xl font-weight-500 lg:text-4xl text-neutral-800 py-4"
                                    />
                                    {errors.title && <p className="text-red-500 text-sm mt-1 font-weight-400">{errors.title}</p>}
                                </>
                            }
                        </h1>
                        {/* Event description */}
                        {!isEditMode ?
                            <>
                                {description &&
                                    <p className="mt-2 text-md text-white/95 line-clamp-4">
                                        {description}
                                    </p>
                                }
                            </>
                            :
                            <Textarea
                                id="description"
                                placeholder="Enter your event's description."
                                value={editedEvent?.description}
                                onChange={(e) => setEditedEvent(prevState => ({
                                    ...prevState,
                                    description: e.target.value
                                }))}
                                className="my-4 text-md text-muted-foreground"
                            />
                        }
                        {/* Event date */}
                        {!isEditMode ?
                            <>
                                {starting_date &&
                                    <div className="mt-4 flex text-white">
                                        <p>
                                            <span>{moment(starting_date).format("Do MMMM YYYY")}</span>
                                            {ending_date && ending_date !== starting_date &&
                                                <span>&nbsp;-&nbsp;{moment(ending_date).format("Do MMMM YYYY")}</span>
                                            }
                                        </p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <DateRangePicker date={localDate} handleDateChange={handleDateChange}/>
                                {errors.date && <p className="text-red-500 text-sm mt-1 col-span-3 font-weight-400">{errors.date}</p>}
                            </>
                        }
                    </div>
                </div>
            </div>
            {/* Card bottom */}
            <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start items-center md:items-start mt-8">
                {/* Left Section */}
                <div className="w-full md:w-1/2 max-w-md px-8 space-y-6">
                    {/* Public gallery */}
                    <div>
                        {!isEditMode ? (
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="flex items-center">
                                    {isPublic ? (
                                        <Globe className="w-6 h-6 text-green-500"/>
                                    ) : (
                                        <GlobeLock className="w-6 h-6 text-red-500"/>
                                    )}
                                    <div className="flex items-center gap-2">
                                        <p className="text-lg ml-1.5 mr-2">
                                            <b>{isPublic ? 'Public' : 'Private'}</b> event gallery
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-start mt-4 lg:mt-0 lg:ml-1">
                                    <Button
                                            variant="outline"
                                            size="sm"
                                            asChild
                                            className="flex items-center justify-center"
                                        >
                                            <Link to={galleryUrl} className="inline-block text-center"
                                                  target="_blank" rel="noopener noreferrer"
                                            >
                                                View gallery&nbsp;&rarr;
                                            </Link>
                                        </Button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="p-4 mt-4 border rounded-sm border-grey-200 grid grid-cols-12 flex justify-center items-bottom">
                                <div className="col-span-10">
                                    <h4 className="text-lg font-weight-400">Public event gallery</h4>
                                    <p className="text-sm">
                                        Publish an event gallery that’s accessible to everyone online. Viewers can
                                        explore event details, photos, and grouped results, and you'll be able to
                                        share a link with anyone.
                                    </p>
                                </div>
                                <div className="col-span-2 flex items-center justify-center">
                                    <Switch
                                        checked={editedEvent.isPublic}
                                        onCheckedChange={(checked) =>
                                            setEditedEvent((prevState) => ({
                                                ...prevState,
                                                isPublic: checked,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Allow downloads */}
                    <div>
                        {!isEditMode ? (
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="flex items-center">
                                    {allowDownloads ? (
                                        <ImageDown className="w-6 h-6 text-green-500"/>
                                    ) : (
                                        <ImageOff className="w-6 h-6 text-red-500"/>
                                    )}
                                    <div className="flex items-center gap-2">
                                        <p className="text-lg ml-1.5 mr-2">
                                            <b>{allowDownloads ? 'Enabled' : 'Disabled'}</b>&nbsp;photo downloads
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="p-4 mt-4 border rounded-sm border-grey-200 grid grid-cols-12 flex justify-center items-bottom">
                                <div className="col-span-10">
                                    <h4 className="text-lg font-weight-400">Photo downloads</h4>
                                    <p className="text-sm">
                                        Enable users to download high-quality <b>original</b> photo files directly from the event gallery.
                                    </p>
                                </div>
                                <div className="col-span-2 flex items-center justify-center">
                                    <Switch
                                        checked={editedEvent.allowDownloads}
                                        onCheckedChange={(checked) =>
                                            setEditedEvent((prevState) => ({
                                                ...prevState,
                                                allowDownloads: checked,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Right Section */}
                <div
                    className="w-full md:w-1/2 max-w-md grid grid-cols-3 items-center gap-2 md:border-l-2 border-gray-200 mt-8 md:mt-0">
                    <div>
                        <div className="flex justify-center items-center rounded-lg">
                            <SquareUserRound className="flex-shrink-0 w-10 h-10 text-primary"/>
                        </div>
                        <div className="mt-5 flex justify-center items-center">
                            <div className="text-center">
                                <p className="mt-1 text-lg text-muted-foreground font-weight-400">
                                    Portraits
                                </p>
                                <p className="flex justify-center mt-1 text-2xl font-weight-400">
                                    {classification ? (
                                        `${portraits_count}`
                                    ) : (
                                        <Minus className="pt-1 pb-1 text-neutral-800 w-9 h-9"/>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center items-center">
                            <ImageUp className="flex-shrink-0 w-10 h-10 text-primary"/>
                        </div>
                        <div className="mt-5 flex justify-center items-center">
                            <div className="text-center">
                                <p className="mt-1 text-lg text-muted-foreground font-weight-400">Photos</p>
                                <p className="mt-1 text-2xl font-weight-400">{photos_count}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center items-center rounded-lg">
                            <ScanFace className="flex-shrink-0 w-10 h-10 text-primary"/>
                        </div>
                        <div className="mt-5 flex justify-center items-center">
                            <div className="text-center">
                                <p className="mt-1 text-lg text-muted-foreground font-weight-400">Status</p>
                                <Badge variant={status.toLowerCase()} className="mt-2 h-7.5">
                                    {status}
                                </Badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CardContent>
    );
};

export default EventDetails;
