import React from 'react';
import { LockKeyhole} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import logo from '@/assets/icons/logo-vertical.png';


const PrivateGallery = () => {
    return (
        <div className="flex items-start justify-center min-h-screen bg-gray-100 pt-20">
            <div className="flex flex-col items-center mt-8 w-[500px]">
                <Link to="/">
                    <img src={logo} alt="Logo" width="350"/>
                </Link>
                <Card className="w-full max-w-lg px-7 py-6 gap-4 bg-white shadow-md rounded-lg">
                    <div className="flex justify-center items-center my-4">
                        <LockKeyhole className="h-12 w-12 mr-2 text-xl text-center text-red-500"/>
                    </div>
                    <p className="text-xl text-center my-4">This event gallery is <b>Private</b></p>
                    <Separator className="my-3 bg-black bg-opacity-20"/>
                    <Link to="/">
                        <p className="text-primary text-md cursor:pointer hover:underline cursor-pointer mt-4 text-center">
                            Return to homepage
                        </p>
                    </Link>
                </Card>
            </div>
        </div>
    )
};

export default PrivateGallery;
