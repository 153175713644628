import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader2, PlusCircle } from 'lucide-react';
import moment from 'moment';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { createEvent, getEventTypes } from '@/redux/actions';
import DateRangePicker from '@/components/ui/DateRangePicker';
import EventTypeDropdownSelector from '@/components/ui/EventTypeDropdownSelector';


const CreateEventModal = ({ verified }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const businessId = useSelector(state => state.user?.business?.id);
    const eventTypes = useSelector(state => state.lookups?.eventTypes);
    const { isCreateEventLoading } = useSelector(state => state.events?.state);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState({from: new Date()});
    const [mainImage, setMainImage] = useState(null);
    const [eventType, setEventType] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(getEventTypes());
    }, [dispatch]);

    const handleSubmit = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'Please fill in the event tile';
        if (!date?.from) newErrors.date = 'Please pick the event date(s)';
        if (!eventType) newErrors.eventType = 'Please select an event type';

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('starting_date', moment(date.from).format("YYYY-MM-DD"));
        if (date.to) {
            formData.append('ending_date', moment(date.to).format("YYYY-MM-DD"));
        }
        if (mainImage) {
            formData.append('main_image', mainImage);
        }
        formData.append('event_type', eventType);

        dispatch(createEvent({ businessId, formData, navigate }));
    };


    return (
        <Dialog>
            <DialogTrigger asChild disabled={!verified}>
                <Button className="h-12 w-[160px] gap-1">
                    <PlusCircle className="h-[22px] w-[22px] mr-2"/>
                    <span className="text-lg">Create event</span>
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]" onInteractOutside={(e) => e.preventDefault()}>
                <DialogHeader>
                    <DialogTitle className="text-xl">Create your event</DialogTitle>
                    <DialogDescription>
                        Fill in your event details to create a new event. You will then be able to upload your photos and unlock the power of AI!
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-5 py-4">
                    <div className="grid grid-cols-4 items-center gap-5">
                        <Label htmlFor="title" className="text-right">
                            Title<span className="text-red-800 text-sm ml-0.5">*</span>
                        </Label>
                        <div className="col-span-3">
                            <Input
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="col-span-3"
                            />
                            {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-5">
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-5">
                        <Label htmlFor="dates" className="text-right">
                            Date(s)<span className="text-red-800 text-sm ml-0.5">*</span>
                        </Label>
                        <div className="col-span-3">
                            <DateRangePicker date={date} handleDateChange={setDate} />
                            {errors.date && <p className="text-red-500 text-sm mt-1 col-span-3">{errors.date}</p>}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-5">
                        <Label htmlFor="mainImage" className="text-right">
                            Main image
                        </Label>
                        <div className="col-span-3">
                            <Input
                                id="mainImage"
                                type="file"
                                accept="image/*"
                                onChange={(e) => setMainImage(e.target.files[0])}
                            />
                            {errors.mainImage && <p className="text-red-500 text-sm mt-1">{errors.mainImage}</p>}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-5 items-center">
                        <Label htmlFor="country" className="text-right">
                            Event type<span className="text-red-800 text-sm ml-0.5">*</span>
                        </Label>
                        <div className="col-span-3">
                            <EventTypeDropdownSelector
                                eventTypes={eventTypes}
                                eventType={eventType}
                                onChange={(e) => setEventType(e.target.value)}
                            />
                            {errors.eventType &&
                                <p className="text-red-500 text-sm mt-1 w-max">{errors.eventType}</p>}
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    {isCreateEventLoading ?
                        <Button variant="disabled" className="text-md cursor-default">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                            <span>Creating event</span>
                        </Button>
                        :
                        <Button variant="default" className="text-md" type="submit" onClick={handleSubmit}>
                            Create event
                        </Button>
                    }
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};

export default CreateEventModal;