import React, { useEffect } from 'react';
import Footer from '@/containers/Footer';
import Navbar from '@/components/Legal/Navbar';

const TermsOfService = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className="container mx-auto p-8 max-w-4xl bg-card my-6 rounded-lg">
                <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
                <p className="text-gray-700 mb-6">
                    Effective Date: <span className="italic">01/01/2025</span>
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. Introduction & Agreement to Terms</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    Welcome to <strong>bestShot.ai</strong>, a product offered by <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>,
                    located at Filikis Etaireias 12 & Tsimiski, Greece ("Company," "we," "our," or "us"). These Terms of Service
                    ("Terms") govern your use of our platform, located at <a href="https://bestshot.ai" className="text-blue-500 underline">https://bestshot.ai</a> (the "Platform").
                    By using the Platform, you agree to these Terms.
                </p>
                <p className="text-gray-700 mb-6">
                    If you do not agree to these Terms, you may not access or use the Platform.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Services Provided</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    The Platform provides AI-driven solutions for photographers, event organizers, and businesses to manage, organize, and distribute event photos. Specific services include:
                    <ul className="list-disc list-inside mb-6">
                        <li>Organize and group photos by face recognition and other attributes.</li>
                        <li>AI-powered photo tagging and sorting.</li>
                        <li>Create personalized galleries for distribution or sale.</li>
                        <li>Access a white-label solution for custom branding.</li>
                        <li>Payment processing for photo purchases.</li>
                    </ul>
                </div>

                <h3 className="text-lg font-bold mb-2">Service Limitations</h3>
                <p className="text-gray-700 mb-6">
                    - The Platform is <strong>not a storage solution</strong>. Photos are processed and temporarily stored for organizational purposes but are not retained long-term.<br />
                    - Face recognition accuracy may vary and is not guaranteed.
                </p>

                <h2 className="text-2xl font-semibold mb-4">3. Account Registration and Responsibilities</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <h3 className="text-lg font-bold mb-2">Registration</h3>
                <div className="text-gray-700 mb-6">
                    To access the Platform, users must create an account by providing:
                    <ul className="list-disc list-inside mb-6">
                        <li>Personal details: Name, email, phone number.</li>
                        <li>Business details: Company name, VAT, address, website.</li>
                    </ul>
                </div>

                <h3 className="text-lg font-bold mb-2">User Responsibilities</h3>
                <div className="text-gray-700 mb-6">
                    As a user, you agree to:
                    <ul className="list-disc list-inside mb-6">
                        <li>Provide accurate, current, and complete information during registration.</li>
                        <li>Maintain the confidentiality of your account credentials.</li>
                        <li>Use the Platform in compliance with all applicable laws.</li>
                        <li>Respect intellectual property rights and not misuse content.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">4. Pricing Payment Terms</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <h3 className="text-lg font-bold mb-2">Pricing</h3>
                <p className="text-gray-700 mb-6">
                    The Platform operates on a pay-as-you-go basis. Users are charged based on the number of photos uploaded to the platform according to an agreed-upon rate stated in the Service agreement.
                </p>

                <h3 className="text-lg font-bold mb-2">Recurring Invoicing</h3>
                <div className="text-gray-700 mb-6">
                    <ul className="list-disc list-inside mb-6">
                        <li>Invoices are issued monthly based on the volume of photos uploaded.</li>
                        <li>Payments are processed securely through third-party payment providers.</li>
                    </ul>
                </div>

                <h3 className="text-lg font-bold mb-2">Refund Policy</h3>
                <p className="text-gray-700 mb-6">
                    No refunds are offered for uploaded photos. Users may cancel their subscriptions at any time, with no questions asked.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    <ul className="list-disc list-inside mb-6">
                        <li><a href="https://bestshot.ai/" className="text-blue-500 underline">bestShot.ai</a> owns all intellectual property related to the platform.</li>
                        <li>Users retain rights to uploaded content but grant us a license for hosting and distribution purposes.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    To the fullest extent permitted by law:
                    <ul className="list-disc list-inside mb-6">
                        <li><a href="https://bestshot.ai/" className="text-blue-500 underline">bestShot.ai</a> is not liable for indirect, incidental, or consequential damages.</li>
                        <li>Total liability is capped at the amount you paid for our services.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">7. Indemnification</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    You agree to indemnify <a href="https://bestshot.ai/" className="text-blue-500 underline">bestShot.ai</a> against claims arising from your use of the platform, including infringement or misuse of content.
                </p>

                <h2 className="text-2xl font-semibold mb-4">8. Termination</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    We may suspend or terminate access for violations of these Terms. Upon termination, access to photos and account data may be restricted.
                </p>

                <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    These Terms are governed by the laws of Greece. Disputes will be resolved exclusively in the courts of Thessaloniki, Greece.
                </p>

                <h2 className="text-2xl font-semibold mb-4">10. Changes to the Terms of Service</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    We may update these Terms of Service periodically to reflect changes in our practices or legal requirements. The revised terms will be posted with the effective date. Any updates will be communicated via email or a prominent notice on the platform.
                </p>

                <h2 className="text-2xl font-semibold mb-4">11. Contact Information</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <address className="text-gray-700">
                    <strong>Support Team</strong><br />
                    <a href="https://bestshot.ai/" className="text-blue-500 underline">bestShot.ai</a><br />
                    Address: Filikis Etaireias 12 & Tsimiski, Greece<br />
                    Email: <a href="mailto:privacy@bestshot.ai" className="text-blue-500 underline">privacy@bestshot.ai</a>
                </address>
            </div>
            <Footer />
        </>
    );
};

export default TermsOfService;