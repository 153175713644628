import React from 'react';
import GalleryHeader from '@/components/Gallery/GalleryHeader';
import Footer from '@/containers/Footer';


export const withGalleryHeaderAndFooter = (WrappedComponent) =>
    function (props) {
        return (
            <div className="flex flex-col min-h-screen">
                <GalleryHeader />
                <main className="flex-grow">
                    <WrappedComponent {...props} />
                </main>
                <Footer />
            </div>
        );
    };

export default withGalleryHeaderAndFooter;