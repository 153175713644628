import React, { useEffect } from 'react';
import Footer from '@/containers/Footer';
import Navbar from '@/components/Legal/Navbar';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className="container mx-auto p-8 max-w-4xl bg-card my-6 rounded-lg">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                <p className="text-gray-700 mb-6">
                    Effective Date: <span className="italic">01/01/2025</span>
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    At <strong>bestShot.ai</strong>, operated by <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>, we value your privacy
                    and are committed to protecting your personal data. This Privacy Policy outlines how we collect,
                    use, and protect your data in compliance with the General Data Protection Regulation (GDPR) and
                    other applicable laws.
                </p>
                <p className="text-gray-700 mb-6">
                    By accessing or using our services, you consent to the practices described in this Privacy Policy. If you do not agree, please discontinue use of our platform.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Data We Collect</h2>
                <div className="border-t border-gray-300 mb-4"></div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.1 Personal and Business Data</h3>
                    <p className="text-gray-700">
                        - Name<br/>
                        - Email address<br/>
                        - Phone number<br/>
                        - Company name<br/>
                        - Business address<br/>
                        - VAT/Tax ID<br/>
                        - Payment information (processed securely by third-party payment gateways)<br/>
                        - Account details such as username and password.
                    </p>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.2 Event-Related Data</h3>
                    <p className="text-gray-700">
                        - Photos uploaded to the platform for processing and organization. These are temporarily stored and processed but not retained long-term unless explicitly agreed upon.<br/>
                        - Metadata associated with photos (e.g., timestamps, location).
                    </p>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.3 Technical Information & Usage Data</h3>
                    <p className="text-gray-700">
                        - Information about how you use our platform (e.g., login times, IP addresses, device information, operating system, and browser type)<br/>
                        - Usage data, including clicks, time spent on pages, and actions taken within the platform.<br/>
                        - Cookies and similar technologies (see our <a href="/cookie-policy" className="text-blue-500 underline">Cookies Policy</a>).
                    </p>
                </div>

                <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We process your data for the following purposes:
                    <ul className="list-disc list-inside">
                        <li>Service Delivery: Provide the services outlined in our Terms of Service.</li>
                        <li>Payments: To process transactions securely.</li>
                        <li>Communication: To send account-related notifications, service updates, and marketing communications (you can opt out of marketing).</li>
                        <li>Compliance: To comply with legal obligations, such as fraud prevention and regulatory requirements.</li>
                        <li>Analytics: To analyze user behavior for platform optimization and marketing insights.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">4. Legal Basis for Processing (GDPR Compliance)</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We process your data based on the following legal grounds:
                    <ul className="list-disc list-inside">
                        <li><strong>Contractual Necessity</strong>: To deliver services as per the agreement between you and us.</li>
                        <li><strong>Compliance with legal obligations</strong>: For tax reporting, invoicing, and other statutory requirements.</li>
                        <li><strong>Legitimate interest</strong>: To improve our platform and secure our operations.</li>
                        <li><strong>Consent</strong>: Where required, such as for cookies and optional data collection.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">5. How We Protect Your Data</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We implement strict security measures to safeguard your personal data, including:
                    <ul className="list-disc list-inside">
                        <li>Hosting all data on <strong>Google Cloud Platform (GCP)</strong>, which complies with GDPR and offers high-security standards.</li>
                        <li>Encryption of sensitive data in transit and at rest.</li>
                        <li>Access controls to limit data access to authorized personnel only.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">6. How We Share Your Information</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We may share your information in the following scenarios:
                    <ul className="list-disc list-inside">
                        <li><strong>Sub-processors</strong>: Third-party providers such as payment processors and cloud hosting services (e.g., Google Cloud Platform).</li>
                        <li><strong>Authorities</strong>: When required by law or to protect our legal rights.</li>
                    </ul>
                    We do not sell or rent your personal data to third parties.
                </div>

                <h2 className="text-2xl font-semibold mb-4">7. Data Retention</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy:
                    <ul className="list-disc list-inside">
                        <li><strong>Uploaded photos</strong>: Photos and associated data will be retained for the period specified in the Service Agreement or deleted upon request.</li>
                        <li><strong>Account information</strong>: Retained for the duration of your use of the platform and as required for legal or business purposes.</li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">9. Data Security</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    We implement industry-standard measures to secure your data, including encryption, firewalls, and access controls. Despite these measures, no system is completely secure, and we cannot guarantee absolute data security.
                </p>

                <h2 className="text-2xl font-semibold mb-4">10. Your Rights</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    As a user in the EU, you have the following rights:
                    <ul className="list-disc list-inside">
                        <li><strong>Right to Access</strong>: Request access to your personal data.</li>
                        <li><strong>Right to Rectification</strong>: Correct any inaccurate or incomplete data.</li>
                        <li><strong>Right to Erasure</strong>: Request deletion of your personal data, subject to legal retention requirements.</li>
                        <li><strong>Right to Data Portability</strong>: Obtain your data in a structured, machine-readable format.</li>
                        <li><strong>Right to Restriction</strong>: Limit how your data is processed.</li>
                        <li><strong>Right to Object</strong>: Object to the processing of your data for specific purposes.</li>
                        <li><strong>Withdraw Consent</strong>: Revoke previously granted consent.</li>
                    </ul>
                </div>

                <p className="text-gray-700 mb-6">
                    To exercise these rights, contact us at <a href="mailto:privacy@bestshot.ai" className="text-blue-500 underline">privacy@bestshot.ai</a>.
                </p>

                <div className="text-gray-700 mb-6">
                    <strong>Right to Lodge a Complaint</strong><br />
                    If you believe your data protection rights have been violated, you have the right to lodge a complaint with the Hellenic Data Protection Authority:
                    <ul className="list-disc list-inside">
                        <li><strong>Address</strong>: 1-3 Kifisias Avenue, Athens, GR 11523, Greece</li>
                        <li><strong>Phone</strong>: +302106475600</li>
                        <li><strong>Email</strong>: <a href="mailto:contact@dpa.gr" className="text-blue-500 underline">contact@dpa.gr</a></li>
                    </ul>
                </div>

                <h2 className="text-2xl font-semibold mb-4">11. International Data Transfers</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    All data processed through the <strong>bestShot.ai</strong> platform is stored and processed exclusively on servers located within the European Union (EU). Our use of <strong>Google Cloud Platform (GCP)</strong> ensures that data is hosted in compliance with GDPR and maintained on servers located within EU territories. This guarantees that no data is transferred outside the EU unless explicitly agreed upon and supported by GDPR-compliant mechanisms.
                </p>

                <h2 className="text-2xl font-semibold mb-4">12. Changes to this Privacy Policy</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The revised policy will be posted with the effective date. Any updates will be communicated via email or a prominent notice on the platform.
                </p>

                <h2 className="text-2xl font-semibold mb-4">13. Contact Information</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <address className="text-gray-700 mb-6">
                    <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>
                    <br />
                    Address: Filikis Etaireias 12 & Tsimiski, Greece
                    <br />
                    Email: <a href="mailto:privacy@bestshot.ai" className="text-blue-500 underline">privacy@bestshot.ai</a>
                </address>

                <p className="text-gray-700">
                    Thank you for trusting us with your personal data. We are committed to protecting your privacy and ensuring the security of your data.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;