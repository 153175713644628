import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import 'styles/global.css'

import Landing from '@/containers/Landing';
import Login from '@/containers/Login';
import Signup from '@/containers/Signup';
import CreateBusiness from '@/containers/CreateBusiness';
import ResetPassword from '@/containers/ResetPassword';
import SetNewPassword from '@/containers/SetNewPassword';
import Events from '@/containers/Events';
import ManageEvent from '@/containers/ManageEvent';
import Gallery from '@/containers/Gallery';
import GalleryAbout from '@/components/Gallery/GalleryAbout';
import GalleryPhotos from '@/components/Gallery/GalleryPhotos';
import GalleryFaces from '@/components/Gallery/GalleryFaces';
import GalleryFace from '@/components/Gallery/GalleryFace';
import TermsOfService from '@/components/Legal/TermsOfService';
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy';
import CookiePolicy from '@/components/Legal/CookiePolicy';
import RefundAndCancellationPolicy from '@/components/Legal/RefundAndCancellationPolicy';
import BillingAndUsage from '@/containers/BillingAndUsage';
import PrivateRoute from '@/components/routes/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieBanner from '@/components/ui/CookieBanner';
import Tracking from '@/containers/Tracking';
import { shouldShowCookieBanner, setCookieResponse } from '@/utils/cookies';


function App() {
    return (
        <BrowserRouter>
            <Tracking />

            <ToastContainer position="top-center" style={{ width: "450px" }} />
            {shouldShowCookieBanner() &&
                <CookieBanner setCookieResponse={setCookieResponse}/>
            }
            <Routes>
                <Route path="/" element={<Landing />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/signup" element={<Signup />}/>
                <Route path="/create-business" element={<PrivateRoute><CreateBusiness /></PrivateRoute>}/>
                <Route path="/reset-password" element={<ResetPassword />}/>
                <Route path="/reset-password/:uid/:token" element={<SetNewPassword />}/>
                <Route path="/events"  element={ <PrivateRoute><Events/></PrivateRoute> } />
                <Route path="/events/:eventId"  element={ <PrivateRoute><ManageEvent/></PrivateRoute> } />
                <Route path="/gallery/:eventSlug" element={<Gallery />}>
                    <Route index element={<Navigate to="about" />} />
                    <Route path="about" element={<GalleryAbout />}/>
                    <Route path="photos" element={<GalleryPhotos />}/>
                    <Route path="faces" element={<GalleryFaces />}/>
                </Route>
                <Route path="/gallery/:eventSlug/faces/:faceId" element={<GalleryFace />}/>
                <Route path="/terms-of-service" element={<TermsOfService />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                <Route path="/cookie-policy" element={<CookiePolicy />}/>
                <Route path="/refund-and-cancellation-policy" element={<RefundAndCancellationPolicy />}/>
                 <Route path="/billing-and-usage"  element={ <PrivateRoute><BillingAndUsage /></PrivateRoute> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
