import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-6 md:px-8 md:py-0 border-b border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 md:h-16 md:flex-row text-sm text-center">
        <div className="flex flex-col items-center gap-2">
          <div className="flex items-center justify-center gap-6">
            <Link to="/terms-of-service" className="hover:underline">
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <Link to="/cookie-policy" className="hover:underline">
              Cookie Policy
            </Link>
            <Link to="/refund-and-cancellation-policy" className="hover:underline">
              Refunds & Cancellations
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-between gap-6">
          <a href="mailto:support@bestshot.ai" className="hover:underline">
            Contact us
          </a>
          <p>{'Copyright © 2025 bestShot.ai'}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;