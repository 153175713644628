import React  from 'react';
import { Card, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { CardContent, CardHeader } from '@/components/ui/card';
import { getCurrencySymbol } from '@/utils/tools';
import moment from 'moment/moment';

const Invoices = ({ businessBillings }) => {
    const handleDownload = async (invoiceUrl) => {
        const response = await fetch(invoiceUrl);
        const blob = await response.blob();
        const link = document.createElement("a");

        const url = window.URL.createObjectURL(blob);
        const filename = invoiceUrl.split('/').pop();

        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const getInvoiceStatusColor = invoiceStatus => {
        switch (invoiceStatus) {
            case 'Paid':
                return 'text-green-500';
            case 'Overdue':
                return 'text-red-500';
            case 'Pending':
                return 'text-orange-500';
            default:
                return 'text-neutral-800';
        }
    }

    return (
        <Card className="mt-8">
            <CardHeader className="flex flex-col space-y-2 pb-2">
                <CardTitle className="font-weight-500 text-2xl">
                    Past invoices
                </CardTitle>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Invoice</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Invoice date</TableHead>
                            <TableHead>Billing period</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {businessBillings.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>#{item.id}</TableCell>
                                <TableCell className={`${getInvoiceStatusColor(item.invoice_status)} font-weight-400`}>{item.invoice_status}</TableCell>
                                <TableCell>{moment(item.created_on).format("Do MMMM YYYY")}</TableCell>
                                <TableCell>{item.billing_period_label}</TableCell>
                                <TableCell>{getCurrencySymbol(item.total_cost.currency)}{item.total_cost.amount}</TableCell>
                                <TableCell>
                                    {item.invoice ?
                                        <button
                                        onClick={() => handleDownload(item.invoice)}
                                        className="text-sm font-medium text-indigo-600 hover:underline"
                                    >
                                        Download
                                    </button>
                                        :
                                        <p className="text-muted-foreground">Unavailable</p>
                                    }

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default Invoices;
