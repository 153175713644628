import { ImageUp, SquareUserRound, ScanFace } from 'lucide-react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const getSteps = (classification) => {
    if (classification) {
        return [
            {
                index: 0,
                label: 'STEP 1',
                title: 'Upload portraits',
                description: 'Drag and drop your portraits or click the button below to select files.',
                value: 'portraits',
                isFirst: true
            },
            {
                index: 1,
                label: 'STEP 2',
                title: 'Upload photos',
                description: 'Drag and drop your photos or click the button below to select files.',
                value: 'photos',

            },
            {
                index: 2,
                label: 'STEP 3',
                title: 'Download groups',
                description: 'Leverage the power of AI to seamlessly organize your photos.',
                value: 'download',
                isLast: true
            },
        ]
    } else {
        return [
            {
                index: 0,
                label: 'STEP 1',
                title: 'Upload photos',
                description: 'Drag and drop your photos or click the button below to select files.',
                value: 'photos',
                isFirst: true
            },
            {
                index: 1,
                label: 'STEP 2',
                title: 'Download groups',
                description: 'Leverage the power of AI to seamlessly organize your photos.',
                value: 'download',
                isLast: true
            }
        ]
    }
};

export const getCurrentStep = (photosCount, portraitsCount, classification) => {
    const steps = getSteps(classification);
    if ((portraitsCount === 0 && classification) || (photosCount === 0 && !classification)) {
        return steps[0];
    } else if (photosCount > 0 && portraitsCount > 0) {
        return steps[2];
    } else {
        return steps[1]
    }
};

export const getStepIcon = (step, stepsCount) => {
    if (step.index === 0 && stepsCount > 2) {
        return <SquareUserRound />;
    } else if (step.index === 0 && stepsCount < 3) {
        return <ImageUp />;
    } else if (step.index === 2) {
        return <ScanFace />;
    } else if (step.index === 1 && stepsCount > 2) {
        return <ImageUp />;
    } else {
        return <ScanFace />;
    }
}

export const enabledNextStep = (currentStep, photosCount, portraitsCount, classification) => {
    switch (currentStep.value) {
        case 'portraits':
            return portraitsCount > 0;
        case 'photos':
            if (classification) {
                return photosCount > 0 && portraitsCount > 0;
            } else {
                return photosCount > 0;
            }
        default:
            return false
    }
}

export const enabledStep = (step, classification, photosCount, portraitsCount) => {
    switch (step.value) {
        case 'photos':
            return portraitsCount > 0 || !classification;
        case 'download':
            if (classification) {
                return photosCount > 0 && portraitsCount > 0;
            } else {
                return photosCount > 0;
            }
        default:
            return true
    }
}

export const isValidEmail = (email) => {
    /* eslint-disable-next-line */
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const result = email.match(emailRegex);
    return result !== null;
};

export const isValidPassword = (password) => {
    /* eslint-disable-next-line */
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;  // At least 8 characters, one letter, one digit, and can include special characters
    return passwordRegex.test(password);
};

export const isValidUrl = (url) => {
    // eslint-disable-next-line
    let res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
};

export const isValidPhone = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

export const createErrorMessage = (errorMessage, errorData) => {
    console.log('Error Data:', errorData);

    if (!errorData) return  errorMessage;

    if (errorData?.error) {
        return errorMessage += ` ${errorData.error}`;
    }

    const errors = errorData?.errors;
    if (errors && typeof errors === 'object') {
        const firstKey = Object.keys(errors)[0];
        if (firstKey && errors[firstKey] && Array.isArray(errors[firstKey])) {
            return errorMessage += ` ${errors[firstKey][0]}`;
        }
    }

    return errorMessage;
};

export const getCurrencySymbol = (currency) => {
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    INR: "₹",
  };

  return <>{currencySymbols[currency] || currency}</>;
};

export const downloadFile = (url, fileName) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            saveAs(blob, fileName);
        })
        .catch(error => console.error("Download error:", error));
};

export const downloadAsZip = (files) => {
    const zip = new JSZip();
    const filePromises = files.map(file =>
        fetch(file.file)
            .then(response => response.blob())
            .then(blob => {
                zip.file(file.file_name, blob);
            })
    );

    Promise.all(filePromises).then(() => {
        zip.generateAsync({ type: "blob" }).then(blob => {
            saveAs(blob, "bestshotai_photos.zip");
        });
    }).catch(error => console.error("Zip creation error:", error));
}