import React from 'react';
import { CircleCheck, Plus, X } from 'lucide-react';
import { Button } from '@/components/ui/button';


const GalleryPhotoCard = ({ photo, index, openLightbox, handleToggleCart, isInCart }) => {
    const fileName = photo.file_name.split(".")[0];
    return (
        <div className="relative group p-1 hover:scale-[1.01]"
             onClick={() => openLightbox(index)}
        >
            <img
                key={photo.id}
                src={photo.file}
                alt={fileName}
                className="w-full h-auto min-h-28 rounded-sm shadow-md"
            />

            <div
                className="absolute top-0 left-0 w-full flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-1 pointer-events-none"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="w-full bg-black bg-opacity-60 py-4 px-4 space-y-4">
                    <p className="text-white text-xs text-center pointer-events-auto">{fileName}</p>
                </div>
            </div>

            {isInCart && (
                <CircleCheck className="absolute top-2.5 right-2.5 p-0 text-primary bg-white rounded-full w-7 h-7" />
            )}

            <div
                className="absolute bottom-0 left-0 w-full flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-1"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="w-full py-4 px-4 space-y-4">
                    <div className="flex justify-center items-center space-x-6">
                        <Button
                            className="flex justify-center align-center space-x-1"
                            variant="outline"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleToggleCart(photo);
                            }}
                        >
                            {isInCart ? <X className="h-5" /> : <Plus className="h-5" /> }
                            <p className="">{isInCart ? "Remove from cart" : "Add to cart"}</p>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryPhotoCard;
