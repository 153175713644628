import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import moment from 'moment/moment';


const GalleryCover = ({ event }) => {
  return (
    <div className={`relative w-full h-72 bg-cover bg-center z-0 shadow-lg ${event.main_image ? 'bg-gray-100' :'bg-black/70'}`}
         style={{ backgroundImage: `url(${event.main_image})` }}

    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 flex items-end justify-between h-full p-8">
        <div className="flex items-center space-x-4">
          <Avatar className="w-20 h-20 backdrop-blur-md">
            {event?.business?.logo ?
                <AvatarImage src={event?.business?.logo} className="w-full h-full object-cover" />
                :
                <AvatarFallback>{event?.business?.name[0]}</AvatarFallback>
            }
          </Avatar>

          <div>
            <h1 className="text-3xl font-weight-500 lg:text-4xl text-white">{event?.title}</h1>
            <div className="flex items-start align-center text-white font-weight-300 text-lg mt-2">
              <p>{event?.business?.name}</p>
              {event?.starting_date &&
              <div className="flex ml-2">
                &bull;
                <p className="ml-2">
                  <span>{moment(event?.starting_date).format("Do MMMM YYYY")}</span>
                  {event?.ending_date && event?.ending_date !== event?.starting_date &&
                      <span>&nbsp;-&nbsp;{moment(event?.ending_date).format("Do MMMM YYYY")}</span>
                  }
                </p>
              </div>
          }
            </div>

          </div>
        </div>

        <div className="hidden items-start justify-between align-center text-right text-white text-lg md:flex">
          <p className="mr-2">{event?.photos_count} photos</p>
          {event?.photo_groups_count > -1 &&
              <>
                &bull;
                <p className="ml-2">{event?.photo_groups_count} faces</p>
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default GalleryCover;
