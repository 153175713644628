import React, {useEffect} from 'react';
import Footer from '@/containers/Footer';
import Navbar from '@/components/Legal/Navbar';
import { resetCookiePreferences } from '@/utils/cookies';


const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar/>
            <div className="container mx-auto p-8 max-w-4xl bg-card my-6 rounded-lg">
                <h1 className="text-3xl font-bold mb-6">Cookies Policy</h1>
                <p className="text-gray-700 mb-6">
                    Effective Date: <span className="italic">01/01/2025</span>
                </p>
                <p className="text-gray-700 mb-6">
                    <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong> ("we," "our," or "us") operates
                    the <strong>bestShot.ai</strong> platform ("Platform"). This Cookies Policy explains how we use
                    cookies and similar technologies to enhance your experience on our website, in compliance with EU
                    regulations, including the General Data Protection Regulation (GDPR).
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. What Are Cookies?</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    Cookies are small text files stored on your device (computer, tablet, or smartphone) when you visit
                    a website. They help websites recognize your device and remember certain information about your
                    preferences or past actions.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Types of Cookies We Use</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.1 Essential Cookies</h3>
                    <p className="text-gray-700">
                        These cookies are necessary for the operation of our Platform. They enable basic functionalities
                        such as user authentication, session management, and access to secure areas of the website.
                        Without these cookies, some parts of the Platform may not function properly.
                    </p>
                    <div className="text-gray-700 mt-2">
                        Examples:
                        <ul className="list-disc list-inside">
                            <li>Session cookies for maintaining logged-in status.</li>
                            <li>Security cookies to protect user accounts.</li>
                        </ul>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.2 Analytical Cookies</h3>
                    <p className="text-gray-700">
                        These cookies help us understand how visitors interact with our Platform by collecting anonymous
                        information about page visits, time spent on the site, and user behavior. This data helps us
                        improve the functionality and user experience of the Platform.
                    </p>
                    <div className="text-gray-700 mt-2">
                        Examples:
                        <ul className="list-disc list-inside">
                            <li>Google Analytics cookies.</li>
                            <li>Heatmap tracking cookies.</li>
                        </ul>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.3 Functional Cookies</h3>
                    <p className="text-gray-700">
                        These cookies remember your preferences and choices, such as language selection or personalized
                        settings, to provide a tailored user experience.
                    </p>
                    <div className="text-gray-700 mt-2">
                        Examples:
                        <ul className="list-disc list-inside">
                            <li>Language preference cookies.</li>
                            <li>Remembering your consent preferences.</li>
                        </ul>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.4 Marketing and Advertising Cookies</h3>
                    <p className="text-gray-700">
                        We may use these cookies to deliver personalized ads based on your browsing behavior and
                        interests. These cookies track your activity across websites to create a profile of your
                        interests.
                    </p>
                    <div className="text-gray-700 mt-2">
                        Examples:
                        <ul className="list-disc list-inside">
                            <li>Ad targeting cookies.</li>
                            <li>Retargeting cookies.</li>
                        </ul>
                    </div>
                </div>

                <h2 className="text-2xl font-semibold mb-4">3. How We Use Cookies</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    We use cookies to:
                    <ul className="list-disc list-inside">
                        <li>Enable core functionalities of the Platform.</li>
                        <li>Analyze traffic and usage patterns to optimize performance.</li>
                        <li>Improve user experience by remembering preferences.</li>
                        <li>Deliver relevant advertisements (where applicable).</li>
                    </ul>

                </div>

                <h2 className="text-2xl font-semibold mb-4">4. Managing Your Cookie Preferences</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    When you first visit our website, you will see a cookie consent banner. You can choose to:
                    <ul className="list-disc list-inside">
                        <li>Accept all cookies.</li>
                        <li>Customize your cookie preferences.</li>
                        <li>Reject non-essential cookies.</li>
                    </ul>
                    You can also manage your cookie preferences at any time by adjusting the settings in your browser or
                    using our cookie settings page.
                </div>
                <p className="text-gray-700 mb-4">
                    <strong>Disabling Cookies</strong><br />
                    Please note that disabling certain types of cookies, especially essential cookies, may impact your
                    ability to use some features of the Platform.
                </p>
                <p className="text-gray-700 mb-6">
                    You can reset your Cookies preferences by clicking&nbsp;
                    <span className="text-primary hover:underline cursor-pointer" onClick={resetCookiePreferences}>
                        here
                    </span>.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Third-Party Cookies</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    Some cookies on our Platform are placed by third-party services, such as:
                    <ul className="list-disc list-inside">
                        <li><strong>Google Analytics</strong> for site analytics.</li>
                        <li><strong>Ad services</strong> for targeted advertisements.</li>
                    </ul>
                    We ensure that all third-party providers comply with GDPR and other relevant privacy
                    regulations.
                </div>

                <h2 className="text-2xl font-semibold mb-4">6. Data Retention</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="text-gray-700 mb-6">
                    Cookies are stored on your device for varying durations:
                    <ul className="list-disc list-inside">
                        <li><strong>Session cookies</strong>: Deleted when you close your browser.</li>
                        <li><strong>Persistent cookies</strong>: Remain on your device until they expire or you
                            delete them.
                        </li>
                    </ul>
                    Specific retention periods are outlined in our <a href="/privacy-policy"
                                                                      className="text-blue-500 underline">Privacy
                    Policy</a>.
                </div>

                <h2 className="text-2xl font-semibold mb-4">7. Updates to This Cookies Policy</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    We may update this Cookies Policy to reflect changes in technology, legal requirements, or how
                    we use cookies. Any updates will be posted on this page with a new effective date.
                </p>

                <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <address className="text-gray-700">
                    <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>
                    <br/>
                    Address: Filikis Etaireias 12 & Tsimiski, Greece
                    <br/>
                    Email: <a href="mailto:support@bestshot.ai"
                              className="text-blue-500 underline">support@bestshot.ai</a>
                </address>
            </div>
            <Footer/>
        </>
    );
};

export default CookiePolicy;