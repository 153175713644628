import { takeEvery, takeLatest } from 'redux-saga/effects';

import * as c from '@/redux/constants/photos';

import { uploadPhotoSaga, uploadPhotosSaga } from './uploadPhotos';
import { downloadPhotosSaga } from './downloadPhotos';


export default function *photosSagas() {
    yield takeEvery(c.UPLOAD_PHOTO, uploadPhotoSaga);
    yield takeLatest(c.UPLOAD_PHOTOS, uploadPhotosSaga);
    yield takeLatest(c.DOWNLOAD_PHOTOS, downloadPhotosSaga);
}
