import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import { Button } from '@/components/ui/button';
import { Trash2 } from 'lucide-react';
import { getEvent, startPollingEvent, stopPollingEvent, deleteEvent, editEvent } from '@/redux/actions';
import { Link, useParams } from 'react-router-dom';
import withHeaderAndFooter from '@/components/HOCs/withHeaderAndFooter';
import EventDetails from '@/components/Events/EventDetails';
import { Card, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import Stepper from '@/components/Photos/Stepper';
import FileUploader from '@/components/Photos/FileUploader';
import { getSteps, getCurrentStep, enabledNextStep } from '@/utils/tools';
import DownloadGroupsStep from '@/components/Photos/DownloadGroupsStep';

const ManageEvent = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: businessId, monthly_photo_quota, current_month_photos_count } = useSelector(state => state.businesses?.business);
    const { isEventLoading, isPolling, isEditEventLoading } = useSelector(state => state.events.state);
    const event  = useSelector(state => state.events.event);
    const { photos_count: photosCount, portraits_count: portraitsCount, classification, status, photo_groups_zip_url: photoGroupsZipUrl, event_type: eventType } = event;
    const steps = useMemo(() => getSteps(classification), [classification]);
    const initialStep = useMemo(() => getCurrentStep(photosCount, portraitsCount, classification), [photosCount, portraitsCount, classification]);
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedEvent, setEditedEvent] = useState({ title: '', description: '', startingDate: '', endingDate: '', mainImage: '', mainImageFile: null, isPublic: false, allowDownloads: false });
    const [errors, setErrors] = useState({});
    const isDeletable = (status.toLowerCase() === 'draft');
    const remainingPhotoQuota =  monthly_photo_quota - current_month_photos_count;

    const initializeEditedEvent = useCallback(() => {
        setEditedEvent({
            title: event.title,
            description: event.description,
            startingDate: event.starting_date,
            endingDate: event.ending_date,
            mainImage: event.main_image,
            isPublic: event.public,
            allowDownloads: event.allow_downloads,
        });
    }, [event]);

    useEffect(() => {
        if (event) {
            initializeEditedEvent()
        }
    }, [event, initializeEditedEvent]);

    useEffect(() => {
        setCurrentStep(initialStep);
    }, [initialStep]);

    useEffect(() => {
        eventId && businessId && dispatch(getEvent({ businessId, eventId }));
    }, [dispatch, businessId, eventId]);

    useEffect(() => {
        if (!status) return;

        if (status.toLowerCase() === 'processing') {
            !isPolling && dispatch(startPollingEvent({ businessId, eventId }));
        } else {
            isPolling && dispatch(stopPollingEvent());
        }
    }, [dispatch, businessId, eventId, status, isPolling]);

    const handleSaveChanges = () => {
        const newErrors = {};
        const { title, description, startingDate, endingDate, mainImage, mainImageFile, isPublic, allowDownloads } = editedEvent;
        if (!title) newErrors.title = 'Title is required';
        if (!startingDate) newErrors.date = 'Date is required';

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        const formData = new FormData();
        if (title && title !== event.title) {
            formData.append('title', title);
        }
        if (description !== event.description) {
            formData.append('description', description);
        }
        if (startingDate && startingDate !== event.starting_date) {
            formData.append('starting_date', startingDate);
        }
        if (endingDate !== event.ending_date) {
            formData.append('starting_date', startingDate);
            formData.append('ending_date', endingDate);
        }
        if (mainImageFile && mainImage && mainImage !== event.main_image) {
            formData.append('main_image', mainImageFile);
        }
        if (isPublic !== event.public) {
            formData.append('public', isPublic);
        }
        if (allowDownloads !== event.allow_downloads) {
            formData.append('allow_downloads', allowDownloads);
        }

        Array.from(formData.keys()).length > 0 && dispatch(editEvent({ businessId, eventId, formData }));
        setIsEditMode(false);
    }

    const handleCancelChanges = () => {
        initializeEditedEvent();
        setIsEditMode(false);
        setErrors({})
    }

    const handleDelete = () => {
        return isDeletable && dispatch(deleteEvent({ businessId, eventId, navigate }));
    }

    if (isEventLoading || isEditEventLoading) {
        return (
        <main className="flex flex-col items-center min-h-screen bg-black/60 p-4 sm:px-6 sm:py-0">
                <Spinner className="mt-44" size="xlarge">
                    <span className="py-3 text-gray-800">Loading your event...</span>
                </Spinner>
        </main>
        )
    }
    return (
        <div>
            <main className="flex flex-col items-center bg-gray-100 p-4 sm:px-6 sm:py-0">
                <Card className="w-full max-w-screen-2xl mt-6">
                    <CardHeader className="flex justify-between text-left">
                        {eventType === 'DEM' && (
                            <div
                                className="bg-gradient-to-r from-indigo-400/90 to-indigo-900/90 border-indigo-900/90 text-white rounded-md p-3 mb-2 w-full text-center">
                                <p className="font-medium">
                                    🎉 This is a Demo Event! Feel free to explore, make edits, and don't forget to take the <b>Product Tour</b>.&nbsp;&nbsp;🚀
                                </p>
                            </div>
                        )}
                        <CardTitle className="text-xl font-weight-400 text-neutral-800 flex justify-between sm:px-6">
                            <Link to="/events">←&nbsp;&nbsp;Events</Link>
                            <div className="flex items-center space-x-2">
                                <Button
                                    variant={isEditMode ? 'default' : 'outline'}
                                    size="lg"
                                    className="text-lg h-10"
                                    onClick={isEditMode ? handleSaveChanges : () => setIsEditMode(true)}
                                >
                                    {isEditMode ? 'Save changes' : 'Edit event'}
                                </Button>
                                {isEditMode &&
                                    <Button
                                        variant="outline"
                                        size="lg"
                                        className="text-lg h-10"
                                        onClick={handleCancelChanges}
                                    >
                                        Cancel
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    className="text-lg h-10 flex items-center justify-center"
                                    variant={`${isDeletable ? 'destructive' : 'disabled'}`}
                                    onClick={handleDelete}
                                >
                                    <Trash2 />
                                </Button>
                            </div>
                        </CardTitle>
                    </CardHeader>
                    <EventDetails event={event} isEditMode={isEditMode} editedEvent={editedEvent} setEditedEvent={setEditedEvent} errors={errors} />
                </Card>

                <div className="flex flex-wrap lg:flex-nowrap gap-4 w-full max-w-screen-2xl my-6">
                    <div className="w-full lg:w-full">
                        {eventType !== 'DEM' ?
                            <Card className="flex justify-between">
                                <div className="w-full lg:w-2/6">
                                    <Stepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep}
                                             classification={classification} photosCount={photosCount}
                                             portraitsCount={portraitsCount}/>
                                </div>
                                <div className="lg:border-l-2 my-4 w-full lg:w-4/6 px-16">
                                    {['photos', 'portraits'].includes(currentStep.value) ?
                                        <FileUploader event={event} remainingPhotoQuota={remainingPhotoQuota}
                                                      currentStep={currentStep}/>
                                        :
                                        <DownloadGroupsStep photoGroupsZipUrl={photoGroupsZipUrl}
                                                            currentStep={currentStep} photosCount={photosCount}
                                                            portraitsCount={portraitsCount} status={status}/>
                                    }
                                    <CardFooter
                                        className="text-md font-weight-400 text-neutral-800 flex justify-between mt-8">
                                        <button
                                            onClick={!currentStep.isFirst ? () => setCurrentStep(steps[currentStep.index - 1]) : null}
                                            className={`${!currentStep.isFirst ? '' : 'text-gray-400/90 cursor-default'}`}
                                        >
                                            &larr;&nbsp;&nbsp;Previous step
                                        </button>
                                        <button
                                            onClick={enabledNextStep(currentStep, photosCount, portraitsCount, classification) ? () => setCurrentStep(steps[currentStep.index + 1]) : null}
                                            className={`${enabledNextStep(currentStep, photosCount, portraitsCount, classification) ? '' : 'text-gray-400/90 cursor-default'}`}
                                        >
                                            Next step&nbsp;&nbsp;&rarr;
                                        </button>
                                    </CardFooter>
                                </div>
                            </Card>
                            :
                            <Card className="flex justify-between">
                                <div className="w-full lg:w-2/6">
                                    <Stepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep}
                                             classification={classification} photosCount={photosCount}
                                             portraitsCount={portraitsCount}/>
                                </div>
                                <div className="lg:border-l-2 my-4 w-full lg:w-4/6 px-16">
                                    <h2>Demo event Tour</h2>
                                {/*    {['photos', 'portraits'].includes(currentStep.value) ?*/}
                                {/*        <FileUploader event={event} remainingPhotoQuota={remainingPhotoQuota}*/}
                                {/*                      currentStep={currentStep}/>*/}
                                {/*        :*/}
                                {/*        <DownloadGroupsStep photoGroupsZipUrl={photoGroupsZipUrl}*/}
                                {/*                            currentStep={currentStep} photosCount={photosCount}*/}
                                {/*                            portraitsCount={portraitsCount} status={status}/>*/}
                                {/*    }*/}
                                {/*    <CardFooter*/}
                                {/*        className="text-md font-weight-400 text-neutral-800 flex justify-between mt-8">*/}
                                {/*        <button*/}
                                {/*            onClick={!currentStep.isFirst ? () => setCurrentStep(steps[currentStep.index - 1]) : null}*/}
                                {/*            className={`${!currentStep.isFirst ? '' : 'text-gray-400/90 cursor-default'}`}*/}
                                {/*        >*/}
                                {/*            &larr;&nbsp;&nbsp;Previous step*/}
                                {/*        </button>*/}
                                {/*        <button*/}
                                {/*            onClick={enabledNextStep(currentStep, photosCount, portraitsCount, classification) ? () => setCurrentStep(steps[currentStep.index + 1]) : null}*/}
                                {/*            className={`${enabledNextStep(currentStep, photosCount, portraitsCount, classification) ? '' : 'text-gray-400/90 cursor-default'}`}*/}
                                {/*        >*/}
                                {/*            Next step&nbsp;&nbsp;&rarr;*/}
                                {/*        </button>*/}
                                {/*    </CardFooter>*/}
                                </div>
                            </Card>
                        }
                    </div>
                </div>
            </main>
        </div>
    );
};

export default withHeaderAndFooter(ManageEvent);
