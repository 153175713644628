export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILURE = 'GET_BUSINESS_FAILURE';

export const EDIT_BUSINESS = 'EDIT_BUSINESS';
export const EDIT_BUSINESS_SUCCESS = 'EDIT_BUSINESS_SUCCESS';
export const EDIT_BUSINESS_FAILURE = 'EDIT_BUSINESS_FAILURE';

export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE';

export const GET_BUSINESS_BILLINGS = 'GET_BUSINESS_BILLINGS';
export const GET_BUSINESS_BILLINGS_SUCCESS = 'GET_BUSINESS_BILLINGS_SUCCESS';
export const GET_BUSINESS_BILLINGS_FAILURE = 'GET_BUSINESS_BILLINGS_FAILURE';