import React from 'react';
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import moment from "moment/moment";
import { Link } from 'react-router-dom';


const GalleryFaceCover = ({ event, galleryPhotoGroup }) => {
    return (
        <div className="w-full max-w-7xl flex flex-col items-center justify-between p-4 gap-6">
            <div className="w-full px-4">
                    <Link
                        className="text-lg font-weight-400 text-neutral-800 flex justify-between"
                        to={`/gallery/${event.slug}/faces`}
                    >
                        ←&nbsp;&nbsp;Gallery
                    </Link>
            </div>
            <div className="flex flex-col items-start space-y-2 w-full">
                <h1 className="text-2xl font-weight-500 text-neutral-800 text-left w-full">
                    {event?.title}
                </h1>
                <div className="flex items-center text-neutral-800 font-weight-300 text-lg w-full">
                    <p>{event?.business?.name}</p>
                    {event?.starting_date && (
                        <div className="flex items-center ml-2">
                            &bull;
                            <p className="ml-2">
                                <span>{moment(event?.starting_date).format("Do MMMM YYYY")}</span>
                                {event?.ending_date && event?.ending_date !== event?.starting_date && (
                                    <span>&nbsp;-&nbsp;{moment(event?.ending_date).format("Do MMMM YYYY")}</span>
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col items-center w-full">
                <Avatar className="w-56 h-56 border-2 rounded-full flex items-center justify-center">
                    <AvatarImage
                        src={galleryPhotoGroup?.preview}
                        className="rounded-full object-cover w-full h-full"
                    />
                </Avatar>
                <div className="w-full flex justify-end mt-2 mb-[-20px]">
                    <p className="text-muted-foreground font-weight-400 text-lg">
                        {galleryPhotoGroup?.photos_count} photo{galleryPhotoGroup?.photos_count > 1 && 's'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GalleryFaceCover;
