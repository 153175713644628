import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardTitle } from '@/components/ui/card';
import { ImageUp, CalendarFold, Euro, Divide } from 'lucide-react';
import { CardContent, CardHeader, CardDescription } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { getCurrencySymbol } from '@/utils/tools';


const UsageStats = ({ businessBillings, verified }) => {

    const [selectedBusinessBillingId, setSelectedBusinessBillingId] = useState("current")
    const {
        monthly_photo_quota: monthlyPhotoQuota,
        current_month_photos_uploaded: currentMonthPhotosUploaded,
        cost_per_photo: curerentMonthCostPerPhoto,
        current_month_cost: currentMonthCost,
        current_month_events_processed: currentMonthEventsProcessed
    } = useSelector(state => state.businesses.business);
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString('en-US', {month: 'long'});
    const year = currentDate.getFullYear();

    const handleQuotaRequest = () => {
        const subject = encodeURIComponent("Monthly photo quota increase request");
        const body = encodeURIComponent("Hello,\n\nI would like to request an increase in my monthly photo upload quota.\n\nThank you!");
        window.location.href = `mailto:support@bestshot.ai?subject=${subject}&body=${body}`;
    };

    const selectedBusinessBilling = selectedBusinessBillingId !== "current"
        ? businessBillings.find(b => b.id === parseInt(selectedBusinessBillingId))
        : null;

    const photosUploaded = selectedBusinessBilling ? selectedBusinessBilling.photos_uploaded : currentMonthPhotosUploaded;
    const eventsProcessed = selectedBusinessBilling ? selectedBusinessBilling.events_processed : currentMonthEventsProcessed;
    const costPerPhoto = selectedBusinessBilling ? selectedBusinessBilling.cost_per_photo : curerentMonthCostPerPhoto;
    const costPerPhotoCurrency = getCurrencySymbol(costPerPhoto.currency);
    const totalCost = selectedBusinessBilling ? selectedBusinessBilling.total_cost : currentMonthCost;
    const totalCostCurrency =  getCurrencySymbol(totalCost.currency)
    const quotaUsage = selectedBusinessBilling ? 100 : 100 * photosUploaded / monthlyPhotoQuota;

    return (
        <>
            {/* Period selector */}
            <div className="flex justify-between items-center">
                <h2 className="font-weight-500 text-2xl mb-4">
                    {selectedBusinessBilling ?
                        selectedBusinessBilling?.billing_period_label
                    :
                    <>
                        Current period&nbsp;<span className="font-weight-300 text-xl">({monthName} {year})</span>
                    </>
                        }

                </h2>
                <Select onValueChange={(value) => setSelectedBusinessBillingId(value)}>
                    <SelectTrigger className="max-w-48 p-4">
                        <SelectValue placeholder="Current period"/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="current" default>Current period</SelectItem>
                        {businessBillings.map((item) => (
                            <SelectItem key={item.id} value={item.id}>
                                {item.billing_period_label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            {/* Usage Stats */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mt-4">
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-md font-weight-300">Photos uploaded</CardTitle>
                        <ImageUp className="text-primary" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-weight-500">{(photosUploaded).toLocaleString()}</div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-md font-weight-300">Events processed</CardTitle>
                        <CalendarFold className="text-primary" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-weight-500">{eventsProcessed}</div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                       <CardTitle className="text-md font-weight-300">Cost per 1,000 photos</CardTitle>
                        <Divide className="text-primary" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-weight-500">
                            {verified ?
                                <>{costPerPhotoCurrency}{(1000 * costPerPhoto.amount).toFixed(2).toLocaleString()}</>
                                :
                                <>-</>
                            }
                        </div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-md font-weight-300">Total cost</CardTitle>
                        <Euro className="text-primary" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-weight-500">
                            {totalCostCurrency}{totalCost.amount.toFixed(2).toLocaleString()}
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* Quota Stats */}
            <Card className="mt-8">
            <CardHeader className="flex flex-col space-y-2 pb-2">
                <CardTitle className="font-weight-500 text-2xl">
                    {selectedBusinessBillingId === 'current' ?
                        <>Monthly usage</>
                        :
                        <>Usage for {selectedBusinessBilling.billing_period_label}</>
                    }
                </CardTitle>
                <CardDescription>
                    {selectedBusinessBillingId === 'current' ?
                        <>You've uploaded <b>{(photosUploaded).toLocaleString()}</b>
                             &nbsp;out of <b>{monthlyPhotoQuota.toLocaleString()}</b> photos this month.
                        </>
                        :
                        <>You uploaded <b>{(photosUploaded).toLocaleString()}</b> photos
                            &nbsp;during {selectedBusinessBilling.billing_period_label}.
                        </>
                    }
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex items-center justify-between w-full">
                <Progress value={quotaUsage} className="w-[450px] h-4 bg-gray-200 [&>div]:bg-primary" />
                <button
                    onClick={handleQuotaRequest}
                    className="ml-4 text-sm font-medium text-indigo-600 hover:underline"
                >
                    Request quota increase
                </button>
            </div>
            </CardContent>
        </Card>
        </>
    );
}

export default UsageStats;