import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';



const CookieBanner = ({ setCookieResponse }) => {
    return (
        <section
            id="cookie-banner"
            className="fixed max-w-lg p-4 mx-auto bg-white border border-gray-200 left-8 bottom-8 rounded-2xl z-[9999] shadow-lg"
        >
            <h2 className="font-weight-500 text-xl">🍪 We use cookies!</h2>

            <p className="mt-4">We use cookies to improve your experience on bestShot.ai.</p>

            <ul className="list-disc mt-4 px-5 space-y-2">
                <li><b>Essential cookies:</b> Required for the platform to function properly.</li>
                <li><b>Optional cookies:</b> Help us understand how you use BestShot.ai so we can improve and
                    personalize your experience.
                </li>
            </ul>

            <p className="mt-4">
                You can choose to accept or decline optional cookies.
                For more details or to update your preferences, check out our&nbsp;
                <Link to="/cookie-policy" className="hover:underline text-primary" target="_blank">
                    Cookie Policy
                </Link>
                &nbsp;and&nbsp;
                <Link to="/privacy-policy" className="hover:underline text-primary" target="_blank">
                    Privacy Policy
                </Link>.
            </p>

            <div className="grid grid-cols-2 gap-4 mt-4 shrink-0">
                <Button variant="outline" onClick={() => setCookieResponse(0)}>
                    Decline optional cookies
                </Button>
                <Button onClick={() => setCookieResponse(1)}>
                    Accept
                </Button>
            </div>
        </section>
    );
};

export default CookieBanner;