import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCart } from '@/redux/actions';
import { X, Download, Copy, Check } from 'lucide-react';
import { Table, TableRow, TableHead, TableCell, TableHeader, TableBody } from '@/components/ui/table';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';


const CartItemsTable = ({ cartItems, allowDownloads, handleDownload }) => {
    const dispatch = useDispatch();
    const [copiedId, setCopiedId] = useState(null);

    const handleCopyFileName = async (item) => {
        try {
            await navigator.clipboard.writeText(item.photo.file_name);
            setCopiedId(item.photo.id);

            setTimeout(() => setCopiedId(null), 2000);
        } catch (err) {
            console.error("Failed to copy:", err);
        }
    };

    const handleRemoveItem = (item) => {
        dispatch(updateCart({ item }))
    }

    return (
        <Table className="table-auto w-full border-t mt-2">
            <TableHeader>
                <TableRow>
                    <TableHead className="text-left hidden lg:table-cell">
                        {cartItems.length} item{cartItems.length > 1 && 's'}
                    </TableHead>
                    <TableHead className="text-left max-w-[100px] sm:max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                        File name
                    </TableHead>
                    <TableHead className="text-center">Actions</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {cartItems.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell className="hidden lg:block lg:table-cell">
                            <img
                                alt={item.photo?.file_name}
                                className="rounded-md object-cover max-h-24 w-36 transition-transform duration-300 transform hover:scale-125"
                                src={item.photo?.file}
                            />
                        </TableCell>
                        <TableCell className="font-medium max-w-[100px] md:max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                            {item?.photo?.file_name}
                        </TableCell>
                        <TableCell className="text-center">
                            <div className="inline-flex items-center space-x-2">
                                {allowDownloads &&
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    className="p-2 h-8 w-8 flex items-center justify-center"
                                                    onClick={() => handleDownload(item)}
                                                >
                                                    <Download/>
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>Download photo</TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                }

                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant={`${copiedId === item.photo.id ? 'successOutline' : 'outline' }`}
                                                className="p-2 h-8 w-8 flex items-center justify-center"
                                                onClick={() => handleCopyFileName(item)}
                                            >
                                                 {copiedId === item.photo.id ? <Check/> : <Copy/> }
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Copy file name</TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>

                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="destructiveOutline"
                                                className="p-2 h-8 w-8 flex items-center justify-center"
                                                onClick={() => handleRemoveItem(item)}
                                            >
                                                <X/>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Remove from cart</TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default CartItemsTable;
