export function getCookie (key) {
    const b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

export function createCookie (name, value, days) {
    let date = new Date(), expires = '';
    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    }
    else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + `; path=/; domain=${process.env.REACT_APP_BASE_DOMAIN.split(':')[0]}`;
}

export function setCookieResponse (accepted) {
    createCookie('cookies_accepted', accepted, 364);
    document.getElementById('cookie-banner').style.display = 'none';
    window.location.reload();
}

export function resetCookiePreferences () {
    document.cookie = `cookies_accepted=; domain=${process.env.REACT_APP_BASE_DOMAIN.split(':')[0]}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    window.location.reload();
}

export function shouldAllowCookies () {
    const cookiesAcceptedCookie = getCookie('cookies_accepted');
    const cookiesAccepted = cookiesAcceptedCookie === '1' || cookiesAcceptedCookie === 1;
    let dntEnabled = false;
    if (window.doNotTrack === '1' || navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes') {
        dntEnabled = true;
    }
    return cookiesAccepted && !dntEnabled;
}

export function shouldShowCookieBanner () {
    const cookielawCookie = getCookie('cookies_accepted');
    return cookielawCookie.toString() !== '0' && cookielawCookie.toString() !== '1';

}