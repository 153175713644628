import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '@/components/ui/spinner';
import GalleryFaceCover from '@/components/Gallery/GalleryFaceCover';
import withGalleryHeaderAndFooter from "../HOCs/withGalleryHeaderAndFooter";
import { getGalleryEvent, getGalleryPhotoGroup } from '@/redux/actions';
import PrivateGallery from '@/components/Gallery/PrivateGallery';
import { Separator } from "@/components/ui/separator";
import GalleryPhotos from '@/components/Gallery/GalleryPhotos';

const GalleryFace = () => {
    const { eventSlug, faceId } = useParams();
    const dispatch = useDispatch();

    const { isGalleryEventLoading } = useSelector(state => state.gallery.state);
    const { galleryEventStatusCode } = useSelector(state => state.gallery.errors);
    const galleryPhotoGroup = useSelector(state => state.gallery.galleryPhotoGroup);
    const event = useSelector(state => state.gallery.galleryEvent);

    useEffect(() => {
        eventSlug && dispatch(getGalleryEvent({ eventSlug }));
        eventSlug && faceId && dispatch(getGalleryPhotoGroup({ eventSlug, photoGroupId: faceId }));
    }, [dispatch, eventSlug, faceId]);


    if (isGalleryEventLoading) {
        return (
        <main className="flex flex-col items-center min-h-screen bg-gray-100 p-4 sm:px-6 sm:py-0">
                <Spinner className="mt-44" size="xlarge">
                    <span className="py-3 text-gray-800">Loading your event...</span>
                </Spinner>
        </main>
        )
    }

    if (galleryEventStatusCode && 400 < galleryEventStatusCode < 500) {
        return <PrivateGallery />
    }

    return (
        <div className="flex flex-col items-center bg-gray-100 pt-20">
            <GalleryFaceCover event={event} galleryPhotoGroup={galleryPhotoGroup}/>
            <Separator className=" max-w-7xl my-4 bg-black bg-opacity-20"/>
            <div className="w-full max-w-7xl mt-2">
                <GalleryPhotos photoGroupId={faceId} />
            </div>
        </div>
    );
};

export default withGalleryHeaderAndFooter(GalleryFace);
