import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Spinner } from '@/components/ui/spinner';
import GalleryPhotoCard from '@/components/Gallery/GalleryPhotoCard';
import GalleryFaceCard from '@/components/Gallery/GalleryFaceCard';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { Button } from '@/components/ui/button';
import ScrollToTopButton from '@/components/ui/ScrollToTopButton';
import { toast } from 'react-toastify';
import { updateCart } from '@/redux/actions';
import { Plus, X } from 'lucide-react';


const GalleryInfiniteList = ({ data, hasMore, next, page = 'photos' }) => {
    const dispatch = useDispatch();

    const event = useSelector(state => state.gallery.galleryEvent);
    const cartItems = useSelector(state => state.user.cart[event.id]) || [];

    const businessName = useSelector(state => state.gallery.galleryEvent?.business?.name || '');
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);

    const openLightbox = (index) => {
        setCurrentIndex(index);
        setLightboxOpen(true);
    };

    const handleToggleCart = (photo) => {
        dispatch(updateCart({ item: { photo, event, businessName } }));
        toast.success("Your cart has been updated.");
    };

    const lightboxSlides = data.map((item) => ({
        src: item.file,
        description: item?.file_name ? item.file_name.split(".")[0] : '',
    }));

    return (
        <>
            <InfiniteScroll
                dataLength={data.length}
                next={next}
                hasMore={hasMore}
                loader={<Spinner size="medium" />}
                endMessage={<p className="p-8 text-muted-foreground text-center">&nbsp;</p>}
                className="w-full"
                style={{ overflow: 'none' }}
            >
                <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4 }}>
                    <Masonry>
                        {data.map((item, index) => (
                            page === 'faces' ? (
                                <GalleryFaceCard key={item.id} face={item} />
                            ) : (
                                <GalleryPhotoCard
                                    key={item.id}
                                    photo={item}
                                    index={index}
                                    openLightbox={openLightbox}
                                    handleToggleCart={handleToggleCart}
                                    isInCart={cartItems.some((cartItem) => cartItem.photo.id === item.id)}
                                />
                            )
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
                <ScrollToTopButton />
            </InfiniteScroll>

            {lightboxOpen && (
                <Lightbox
                    styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)", userSelect: "text", WebkitUserSelect: "text" }}}
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    slides={lightboxSlides}
                    index={currentIndex}
                    on={{ view: ({ index: currentIndex }) => setCurrentIndex(currentIndex) }}
                    plugins={[Captions, Thumbnails]}
                    captions={{ descriptionTextAlign: "center" }}
                    render={{
                        slide: ({ slide }) => {
                            const photo = data.find((item) => item.file === slide.src);
                            if (!photo) return null;

                            const isInCart = cartItems.some((cartItem) => cartItem.photo.id === photo.id);

                            return (
                                <div className="relative flex flex-col items-center">
                                    <img src={slide.src} alt={slide.description} className="max-h-[80vh] object-contain" />

                                    <div className="absolute w-full bottom-0 py-3 px-5 flex flex-col items-center space-y-2">
                                        <div className="flex justify-center items-center text-white/95 space-x-6 pointer-events-auto">
                                            <Button
                                                className="flex justify-center align-center space-x-1"
                                                variant="outline"
                                                onClick={() => {
                                                    handleToggleCart(photo)
                                                }}
                                            >
                                                {isInCart ? <X className="h-5" /> : <Plus className="h-5" />}
                                                <p>{isInCart ? "Remove from cart" : "Add to cart"}</p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        },
                    }}
                />
            )}
        </>
    );
};

export default GalleryInfiniteList;