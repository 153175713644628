import React, { useEffect } from 'react';
import Footer from '@/containers/Footer';
import Navbar from '@/components/Legal/Navbar';


const RefundAndCancellationPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className="container mx-auto p-8 max-w-4xl bg-card my-6 rounded-lg">
                <h1 className="text-3xl font-bold mb-6">Refund & Cancellation Policy</h1>
                <p className="text-gray-700 mb-6">
                    Effective Date: <span className="italic">01/01/2025</span>
                </p>
                <p className="text-gray-700 mb-6">
                    At <strong>bestShot.ai</strong>, operated by <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>, we strive to ensure a clear and transparent understanding of our refund and cancellation policies. By using our platform, you agree to the terms outlined below.
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. Refund Policy</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">1.1 No Refunds for Uploaded Photos</h3>
                    <p className="text-gray-700">
                        Once photos are uploaded to the platform and processed, no refunds will be issued. This policy applies because the primary service—AI-driven photo processing—is completed at the time of upload and processing.
                    </p>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">1.2 Exceptions</h3>
                    <p className="text-gray-700">
                        Refunds may only be considered in exceptional circumstances where:
                    </p>
                    <ul className="list-disc list-inside text-gray-700">
                        <li>A technical issue on our end prevented the successful processing of photos.</li>
                        <li>Duplicate charges were incurred due to a system error.</li>
                    </ul>
                    <p className="text-gray-700 mt-2">
                        To request a refund under these exceptions, you must contact our support team at{' '}
                        <a href="mailto:support@bestshot.ai" className="text-blue-500 underline">
                            support@bestshot.ai
                        </a>{' '}
                        within 14 days of the transaction, providing details of the issue and supporting documentation.
                    </p>
                </div>

                <h2 className="text-2xl font-semibold mb-4">2. Cancellation Policy</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.1 Flexible Cancellation</h3>
                    <p className="text-gray-700">
                        Customers may cancel their use of the platform at any time without restrictions or penalties.
                    </p>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.2 How to Cancel</h3>
                    <p className="text-gray-700">
                        To cancel your account or subscription:
                    </p>
                    <ul className="list-disc list-inside text-gray-700">
                        <li>Log in to your account.</li>
                        <li>Navigate to the account settings page.</li>
                        <li>Select the "Cancel Account" option and confirm.</li>
                    </ul>
                    <p className="text-gray-700 mt-2">
                        Alternatively, you may contact our support team at{' '}
                        <a href="mailto:support@bestshot.ai" className="text-blue-500 underline">
                            support@bestshot.ai
                        </a>{' '}
                        to request cancellation assistance.
                    </p>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">2.3 Effect of Cancellation</h3>
                    <div className="text-gray-700">
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Upon cancellation, access to the platform will terminate immediately.</li>
                            <li>Any outstanding payments for services rendered (e.g., photos already uploaded and
                                processed) will remain due and payable.
                            </li>
                        </ul>
                    </div>
                </div>

                <h2 className="text-2xl font-semibold mb-4">3. Subscription Payments</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">3.1 Recurring Invoices</h3>
                    <p className="text-gray-700">
                        For customers on a recurring payment model, invoices are generated at the end of each month based on the number of photos processed. Any outstanding payments at the time of cancellation must be settled before the account can be fully terminated.
                    </p>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-bold mb-2">3.2 No Prorated Refunds</h3>
                    <p className="text-gray-700">
                        Cancellation does not entitle the user to a prorated refund for the remaining portion of the billing period.
                    </p>
                </div>

                <h2 className="text-2xl font-semibold mb-4">4. Contact Information</h2>
                <div className="border-t border-gray-300 mb-4"></div>
                <p className="text-gray-700 mb-6">
                    For any questions or concerns regarding this Refund and Cancellation Policy, please contact us:
                </p>
                <address className="text-gray-700 mb-6">
                    <strong>DATAMINDS SINGLE MEMBER PRIVATE COMPANY</strong>
                    <br />
                    Address: Filikis Etaireias 12 & Tsimiski, Greece
                    <br />
                    Email:{' '}
                    <a href="mailto:support@bestshot.ai" className="text-blue-500 underline">
                        support@bestshot.ai
                    </a>
                </address>
            </div>
            <Footer />
        </>
    );
};

export default RefundAndCancellationPolicy;