import { put, call, delay } from 'redux-saga/effects';
import { resetCart, updateCart } from '@/redux/actions';
import { toast } from 'react-toastify';
import { request } from '@/utils/request';
import { createErrorMessage, downloadFile, downloadAsZip } from '@/utils/tools';

export function* downloadPhotosSaga ({ eventId, photoIds, clearItem }) {
    try {
        const queryParams = photoIds.map(id => `photo_ids=${id}`).join('&');
        const response = yield call(
            request,
            `/photos/download/?${queryParams}`,
            'GET',
            null,
            {},
            false
        );

        if (!response || response.length === 0) {
            console.error("No files received for download.");
            toast.error("No files available for download.");
            return;
        }

        if (response.length === 1) {
            const file = response[0];
            yield call(downloadFile, file.file, file.file_name);
        } else {
            yield call(downloadAsZip, response);
        }

        yield delay(10000);

        const userConfirmed = window.confirm("Did you save your files?");
        if (userConfirmed) {
            if (clearItem) {
                yield put(updateCart({item: clearItem}));
            } else {
                yield put(resetCart(eventId));
            }
        }

    } catch (error) {
        console.log('error', error);
        let errorMessage = createErrorMessage('There was an error downloading your photos', error?.response?.data)
        toast.error(errorMessage);
        console.log(errorMessage);
    }
}