import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCart, downloadPhotos } from '@/redux/actions';
import { Download, ShoppingCart, X, Copy, Check } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import CartItemsTable from '@/components/Cart/CartItemsTable';


const CartModal = () => {
    const dispatch = useDispatch();

    const event = useSelector(state => state.gallery.galleryEvent);
    const cartItems = useSelector(state => state.user.cart[event.id]) || [];
    const isEmptyCart = cartItems.length === 0;
    const allowDownloads = useSelector(state => state.gallery.galleryEvent?.allow_downloads || false);
    const [copied, setCopied] = useState(false);

    const handleCopyFileNames = async () => {
        try {
            const fileNames = cartItems.map(item => item.photo.file_name).join('\n');
            await navigator.clipboard.writeText(fileNames);
            setCopied(true);

            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error("Failed to copy:", err);
        }
    };

    const handleClearCart = () => {
        dispatch(resetCart(event.id));
    }

    const handleDownload = (item = null) => {
        const photoIds = item ? [item.photo.id] : cartItems.map(item => item.photo.id);
        dispatch(downloadPhotos({ eventId: event.id, photoIds, clearItem: item }))
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <div className="relative flex flex-col items-center justify-center p-1 mx-4 cursor-pointer">
                    <ShoppingCart className="h-7 w-7"/>
                    {cartItems.length > 0 && (
                        <span className="absolute -top-2.5 -right-2 bg-red-600 text-white text-xs font-bold px-2 py-0.5 rounded-full">
                            {cartItems.length}
                        </span>
                    )}
                </div>
            </DialogTrigger>
            <DialogContent className="max-w-4xl min-h-[30vh] max-h-[90vh] overflow-y-auto" onInteractOutside={(e) => e.preventDefault()}>
                {!allowDownloads &&
                    <Alert className="border-yellow-500 bg-yellow-100/60 mb-4 mt-4">
                        <AlertTitle className="text-yellow-700">Downloads are disabled for this event</AlertTitle>
                        <AlertDescription className="text-yellow-700">
                            Downloading photos is currently unavailable for <b>{event.title}</b>.<br/>
                            But don't worry! You can still save your favorite photos to your cart and copy the list of names to get in touch with <b>{event.business.name}</b> directly.

                        </AlertDescription>
                    </Alert>
                }
                <DialogHeader>
                    <DialogTitle className="text-xl font-weight-600">Your cart for&nbsp;
                        <span className="text-primary">{event.title}</span></DialogTitle>
                    <DialogTitle className="text-lg font-weight-400">by <span className="text-primary font-weight-500">{event.business.name}</span></DialogTitle>
                </DialogHeader>
                {isEmptyCart ?
                    <div className="flex flex-col items-center justify-center text-center py-10">
                        <ShoppingCart className="w-20 h-20 mb-4"/>
                        <p className="text-lg font-medium text-gray-600">Your cart is waiting for some great picks!</p>
                        <p className="text-gray-500">Browse the photo galleries and add your favorite shots.</p>
                    </div>
                    :
                    <>
                        <div className="flex justify-end space-x-2 mt-2">
                            {allowDownloads &&
                                <Button onClick={() => handleDownload()}>
                                    <div className="flex justify-between items-center space-x-1">
                                        <Download/>
                                        <p>Download all ({cartItems.length})</p>
                                    </div>
                                </Button>
                            }

                             <Button variant={`${copied ? 'successOutline' : 'outline'}`} onClick={handleCopyFileNames}>
                                <div className="flex justify-between items-center space-x-1">
                                    {copied ? <Check/> : <Copy/>}
                                    <p>{copied ? 'Copied' : 'Copy list'}</p>
                                </div>
                            </Button>

                            <Button variant="destructiveOutline" onClick={handleClearCart}>
                                <div className="flex justify-between items-center space-x-1">
                                    <X/>
                                    <p>Clear cart</p>
                                </div>
                            </Button>
                        </div>
                        <CartItemsTable cartItems={cartItems} allowDownloads={allowDownloads} handleDownload={handleDownload}/>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
};

export default CartModal;
